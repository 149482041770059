import client from "../api/apiClient";

class PedidoEntregaRouteService {
  route;
  client;
  constructor() {
    this.route = "PedidoEntregaRoute";
    this.client = client;
  }
async getAllRoutes(page,limit,filter){
  const route = `${this.route}/filter`;
  return await client.get(route, { params: { page, limit, ...filter } })
}
  async getAll(page, limit, filter) {
    const route = `${this.route}`;
    return await client.get(route, { params: { page, limit, ...filter } });
  }
  async getById(id) {
    const route = `${this.route}/${id}`;
    return await client.get(route, id);
  }

  async insert(item) {
    const route = this.route;
    console.log(item)
    return await client.post(route, item);
  }

  async updateStatus(pedidoEntregaRouteId,pedidoEntregaStatus) {
    const route = `${this.route}/update-status`;
    return await client.put(route,{pedidoEntregaRouteId,pedidoEntregaStatus});
  }
  async update(item) {
    const route = this.route;
    return await client.put(route,item);
  }
  async remove(id) {
    const route = `${this.route}/${id}`;
    return await client.delete(route,id);
  }
}

export default PedidoEntregaRouteService;

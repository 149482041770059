<template>
  <div>
    <div class="is-size-2 mb-3">Acessos de Usuário</div>
    <button class="button is-primary mb-5" @click="listCrud.newItem">Novo</button>
    <table-app
      :headers="headers"
      :items="listCrud.items"
      :actions="actions"
      :rowsLimit="listCrud.pagination.limit"
      :page="listCrud.pagination.page"
      :itemsTotal="listCrud.pagination.total"
      @search="listCrud.getItemsSearch"
    >
    </table-app>
    <modal-app
      v-model="listCrud.modalIsOpen"
      title="Acesso"
      @confirm="listCrud.saveItem"
      @cancel="listCrud.cancelItem"
    >
      <div v-if="listCrud.itemSelected">
        <Form class="mb-5">
          <div class="field">
            <label class="label">Módulo</label>
            <div class="control">
              <Field
                class="input"
                v-model="listCrud.itemSelected.module"
                name="modulo"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="modulo"
              ></ErrorMessage>
            </div>
          </div>
        </Form>

        <hr class="dropdown-divider" />
        <label class="label">Tipos de Acesso</label>
        <UserAccessTypeList
          v-model="listCrud.itemSelected.types"
        ></UserAccessTypeList>
      </div>
    </modal-app>
  </div>
</template>

<script>
import { useListValue } from "../composables/listValue";
import { useListCrud } from "../composables/listCrud";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserAccessService from "../services/UserAccessService";
import UserAccessTypeList from "../forms/UserAccessTypeList.vue";
export default {
  components: { Form, Field, ErrorMessage, UserAccessTypeList },
  data() {
    return {
      listValue: useListValue(null, null),
      service: new UserAccessService(),
      listCrud: useListCrud(new UserAccessService(), ()=>{return{}}),
      pagination: {
        page: 1,
        limit: 30,
        total: 0,
      },
      form: {
        modalIsOpen: false,
        itemSelected: null,
      },
      actions: [],
      headers: [{ text: "Módulo", value: "module" }],
      items: [],
    };
  },

  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },
  },

  async mounted() {
    await this.listCrud.getItems();
    this.actions = [
      {
        name: "Editar",
        color: "yellow",
        class: "has-text-link",
        icon: "fa-solid fa-pen",
        handle: this.listCrud.editItem,
      },
      { name: "Deletar", color: "red", icon: "fa-solid fa-trash",handle: this.listCrud.deleteItem  },
    ];
  },
};
</script>

<style>
</style>
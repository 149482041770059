import ServiceBase from "./ServiceBase";

class AccessGroupService extends ServiceBase {
  constructor() {
    super("accessGroup");
  }

  GetByIdOnUserAccessSelect(id){
    return this.client.get(`${this.path}/useraccess`,{params:{id}})
  }
}

export default AccessGroupService;

import { defineStore } from "pinia";

export const useRouteNewStore = defineStore("routeNewStore", {
    state: () => {
        return {
            selected: [],
        }

},
    actions: {
        setSelected(selected) {
            this.selected = selected
        },
        removeSelected(item){
            const index = this.selected.indexOf(item);
            this.selected.splice(index,1)
        },

        
    }, 
    persist: {
        enabled: true,
      },
});

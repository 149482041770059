<template>
  <div class="is-size-3 has-text-weight-bold mb-5">Pedido Entregas</div>

  <div class="filters">
    <div class="field">
      <label class="label">Data Inicio</label>
      <div class="control">
        <input v-model="filter.startDate" class="input" type="date" placeholder="Data Inicio" />
      </div>
    </div>

    <div class="field">
      <label class="label">Data Fim</label>
      <div class="control">
        <input v-model="filter.endDate" class="input" type="date" placeholder="Data Fim" />
      </div>
    </div>
    <div class="field">
      <label class="label">Cliente</label>
      <div class="select">
        <select v-model="filter.clienteId">
          <option selected value="">Todos</option>
          >
          <option v-for="(cliente, index) in clientes" :key="index" :value="cliente.id">
            {{ cliente.cliente }}
          </option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label">OS</label>
      <div class="control">
        <input v-model="filter.os" class="input" type="text" placeholder="000001" />
      </div>
    </div>
  </div>

  <div>
    <button :disabled="!selected || selected.length == 0" @click="goToRouteNew" class="button is-info">
      Roteirização
    </button>
  </div>

  <div style="display: flex; justify-content: flex-end">
    <button class="button is-primary mb-5" @click="search">Buscar</button>
  </div>
  <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th>
          <label class="checkbox">
            <input type="checkbox" @click="selectAllInList" :checked="isAllSelected" :disabled="selectAllDisabled" />
          </label>
        </th>
        <th>OS</th>
        <th>Data de Entrega</th>
        <th>Produto</th>
        <th>Cliente</th>
        <th>Peso</th>
        <th>Endereço</th>
        <th>Cidade</th>
        <th>Restrições</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <label class="checkbox">
            <input type="checkbox" @click="selectItem(item)" :checked="isSelected(item)" />
          </label>
        </td>
        <td>{{ item.numOrdem }}</td>
        <td>{{ dateFormat(item.dataEntrega) }}</td>
        <td>{{ item.produto }}</td>
        <td>{{ item.cliente }}</td>
        <td>{{ item.peso }}</td>
        <td>{{ item.endereco }}</td>
        <td>{{ item.cidade }}</td>
        <td>{{ item.restricoes }}</td>
        <td>
          <div v-if="getHasPermissionEditar()" class="select-actions">
            <div class="select">
              <select v-model="item.status" @change="selectOnChange(item)">
                <option v-for="(status, index) in statusList" :key="index" :value="status.value">
                  {{ status.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-else>
            {{ getStatusText(item.status) }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="pagination">
    <button :disabled="disabledLastPageComp" class="button is-info is-light" @click="changePage(false, true, false)">
      <span class="icon">
        <i class="fa-solid fa-angles-left"></i>
      </span>
    </button>
    <button class="button is-info is-light" @click="changePage(false, false, false)" :disabled="disabledLastPageComp">
      Anterior
    </button>
    <button :disabled="disabledNextPageComp" class="button is-info is-light" @click="changePage(false, false, true)">
      Próxima
    </button>
    <button :disabled="disabledNextPageComp" class="button is-info is-light" @click="changePage(true, false, true)">
      <span class="icon">
        <i class="fa-solid fa-angles-right"></i>
      </span>
    </button>
  </div>
</template>

<script>
import moment from "moment";
import PedidoEntregaService from "../services/PedidoEntregaService";
import {useRouteNewStore} from "../stores/routeNewStore";

export default {
  data: () => {
    return {
      routeNewStore :useRouteNewStore(),
      pedidoEntregaService: new PedidoEntregaService(),
      statusList: [],
      itemsStatusModified: [],
      items: [],
      page: 1,
      limit: 30,
      total: 0,
      clientes: [],
      selected: [],
      filter: {
        startDate: null,
        endDate: null,
        clienteId: "",
        os: "",

      },
    };
  },
  computed: {
    disabledLastPageComp() {
      return this.page === 1;
    },
    disabledNextPageComp() {
      return this.page == this.lastPage(this.limit, this.total);
    },
    isAllSelected() {
      if (this.items === null || this.items.length === 0) return false;
      return this.items.filter((item) => !this.isSelected(item)).length == 0;
    },
    selectAllDisabled() {
      return this.items === null || this.items.length === 0;
    },
  },
  watch: {
    async page() {
      await this.getAll();
      window.scrollTo(0, 0);
    },
  },
  methods: {
    goToRouteNew() {
      this.routeNewStore.setSelected(this.selected)
      this.$router.push("entregas/route/new")
    },
    deselectAllInList() {
      this.items
        .map((item) => {
          return {
            idEntrega: item.idEntrega,
            idItemEntrega: item.idItemEntrega,
          };
        })
        .forEach((item) => {
          const itemInList = this.items.find(
            (i) =>
              i.idEntrega == item.idEntrega &&
              i.idItemEntrega == item.idItemEntrega
          );
          if (itemInList != null) {
            const index = this.selected.indexOf(itemInList);
            this.selected.splice(index, 1);
          }
        });
    },

    selectAllInList() {
      if (this.isAllSelected) {
        this.deselectAllInList();
        return;
      }
      const itemsAdd = this.items
        .filter((item) => !this.isSelected(item))
        .map((item) => {
          return {
            idEntrega: item.idEntrega,
            idItemEntrega: item.idItemEntrega,
          };
        });

      this.selected = [...this.selected, ...itemsAdd];
    },
    isSelected(item) {
      return (
        this.selected.find(
          (key) =>
            key.idItemEntrega === item.idItemEntrega &&
            key.idEntrega == item.idEntrega
        ) != null
      );
    },
    selectItem(item) {
      const itemExist = this.selected.find(
        (key) =>
          key.idItemEntrega === item.idItemEntrega &&
          key.idEntrega == item.idEntrega
      );
      if (itemExist != null) {
        const index = this.selected.indexOf(itemExist);
        this.selected.splice(index, 1);
        return;
      }

      this.selected.push(item.key);
    },
    setStartAndEndDate() {
      const startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );

      this.filter.startDate = moment(startDate).format("YYYY-MM-DD");
      this.filter.endDate = moment(endDate).format("YYYY-MM-DD");
    },
    async search() {
      this.page = 1;
      await this.getAll();
    },
    getStatusText(statusValue) {
      const statusFind = this.statusList.find((st) => st.value === statusValue);
      if (statusFind) return statusFind.text;
      else return null;
    },
    getHasPermissionEditar() {
      return this.$gates.hasAnyPermission("super|Pedidos Entregas-Editar");
    },
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    async changePage(last, first, next = true) {
      if (last === true) {
        this.page = this.lastPage(this.limit, this.total);
        return;
      }
      if (first === true) {
        this.page = 1;
        return;
      }
      if (!next && this.page == 1) return;
      this.page = next === true ? this.page + 1 : this.page - 1;
    },
    async selectOnChange(item) {
      await this.updateStatus(item);
    },

    verifyItemModified(item) {
      const itemExist = this.itemsStatusModified.find(
        (i) =>
          i.idItemEntrega == item.idItemEntrega && i.idEntrega == item.idEntrega
      );
      return itemExist != null;
    },

    itemStatusModified(item) {
      this.itemsStatusModified.push(item);
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YY");
    },
    async getStatusList() {
      const result = await this.pedidoEntregaService.getStatusList();
      if (result.status != 200) return;
      this.statusList = result.data.data;
    },
    async getAll() {
      const result = await this.pedidoEntregaService.getAll(
        this.page,
        this.limit,
        this.filter
      );

      if (result.status != 200) return;

      this.items = result.data.data.data;

      this.items.forEach((item) => {
        item.key = {
          idEntrega: item.idEntrega,
          idItemEntrega: item.idItemEntrega,
        };
      });

      this.total = result.data.data.total;
    },

    async updateStatus(item) {
      const result = await this.pedidoEntregaService.updateStatus(item);
      if (result.status != 200) return;
      await this.getAll();
    },
    async getClientes() {
      var result = await this.pedidoEntregaService.getClientes();
      if (result.status != 200) return;
      this.clientes = result.data.data;
    },
  },

  async mounted() {
    this.setStartAndEndDate();
    await this.getAll();
    await this.getStatusList();
    await this.getClientes();
  },
};
</script>

<style>
.select-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
import axios from "axios";
import { useMainStore } from "../stores/mainStore";
import router from "../router"

import UserService from "../services/UserService";
import { useNotification } from "@kyvg/vue3-notification";

const client = axios.create({
  baseURL: "https://api.scoppiati.com.br/api",
  validateStatus: (status) => {

    if (status == 403 || status == 401) {
      router.push("/not-authorized");
    }

    return status < 300;
  },
});

client.interceptors.request.use((req) => {
  const mainStore = useMainStore();
  mainStore.setLoading(true);

  const userService = new UserService();
  const token = userService.getUserToken();

  req.headers.authorization = "Bearer " + token;

  return req;
});

client.interceptors.response.use(
  (res) => {
    const mainStore = useMainStore();
    mainStore.setLoading(false);

    let message = "Sucesso";
    if (res.data && res.data.message) message = res.data.message;
    const { notify } = useNotification();

    if (res.config.method != "get") {
      notify({
        position: "top center",
        title: "",
        text: message,
        type: "success",
        duration: 2000,
      });
    }

    return res;
  },
  (error) => {

    const mainStore = useMainStore();
    mainStore.setLoading(false);

    let errors = [`Desculpe, algo deu errado :( ${error.message}`];
      console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      errors = error.response.data.errors;
    }
    console.log(errors)
    let errorsString = null
    try{
       errorsString = errors.join("\n");
    }catch(ex)
    {
      errorsString = JSON.stringify(errors);
    }
  


    const { notify } = useNotification();

    notify({
      position: "top center",
      title: "Error",
      text: errorsString,
      type: "error",
      duration: 60000,
    });

    return error;
  }
);

export default client;

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import InputApp from "./components/InputApp";
import { createPinia } from "pinia";
import TableApp from "./components/TableApp";
import ModalApp from "./components/ModalApp";
import ModalConfirmApp from "./components/ModalConfirmApp"
import {Form, Field, ErrorMessage } from "vee-validate";

import piniaPersist from 'pinia-plugin-persist'
const pinia = createPinia();
pinia.use(piniaPersist)

import VueGates from 'vue-gates'

import Notifications from '@kyvg/vue3-notification'

import {PromiseDialog} from "vue3-promise-dialog"

createApp(App)
  .use(router)
  .use(PromiseDialog)
  .use(Notifications)
  .use(VueGates,{persistent:true})
  .component("Field",Field)
  .component("Form",Form)
  .component("ErrorMessage",ErrorMessage)
  .component("input-app", InputApp)
  .component("table-app", TableApp)
  .component("modal-app", ModalApp)
  .component("modal-confirm-app", ModalConfirmApp)
  .use(pinia)
  .mount("#app");

<template>
  <div>
    <div class="is-size-2 mb-3">Usuários</div>
    <button class="button is-primary mb-5" @click="newUser">Novo</button>
    <table-app
      :headers="headers"
      :items="listCrud.items"
      :actions="actions"
      :rowsLimit="listCrud.pagination.limit"
      :page="listCrud.pagination.page"
      :itemsTotal="listCrud.pagination.total"
      @search="listCrud.getItemsSearch"
    >
    </table-app>
    <modal-app
      v-model="newUserForm.modalIsOpen"
      title="Novo Usuário"
      @confirm="saveNewUser"
      @cancel="cancelNewUser"
      :confirm-ok="true"
    >
      <NewUserForm ref="newUserFormRef" v-model="newUserForm.itemSelected" />
    </modal-app>

    <modal-app
      v-model="resetPasswordForm.modalIsOpen"
      title="Resetar Senha"
      @confirm="saveResetPassword"
      @cancel="cancelResetPassword"
      :confirm-ok="true"
    >
      <ResetPasswordForm
        ref="resetPasswordFormRef"
        v-model="resetPasswordForm.itemSelected"
      >
      </ResetPasswordForm>
      <!-- <div v-if="resetPasswordForm.itemSelected">
        <Form class="mb-5">
          <div class="field">
            <label class="label">Nova Senha</label>
            <div class="control">
              <Field
                class="input"
                v-model="resetPasswordForm.itemSelected.newPassword"
                name="password"
                type="password"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="password"
              ></ErrorMessage>
            </div>
          </div>

          <div class="field">
            <label class="label">Confirmação da Senha</label>
            <div class="control">
              <Field
                class="input"
                v-model="resetPasswordForm.itemSelected.confirmPassword"
                name="confirmPassword"
                type="password"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="confirmPassword"
              ></ErrorMessage>
            </div>
          </div>
        </Form>
      </div> -->
    </modal-app>

    <modal-app
      v-model="accessGroupsForm.modalIsOpen"
      title="Grupos de Acesso"
      @confirm="saveAccessGroup"
      @cancel="cancelAccessGroup"
      :confirm-ok="true"
    >
      <div
        v-if="
          accessGroupsForm.accessGroups && accessGroupsForm.accessGroups.length
        "
      >
        <div>
          <table
            class="
              table
              is-bordered is-striped is-narrow is-hoverable is-fullwidth
            "
          >
            <thead>
              <tr>
                <th>Grupo de Acesso</th>
                <th>Concedido</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(accessGroup, index) in accessGroupsForm.accessGroups"
                :key="index"
              >
                <td>{{ accessGroup.name }}</td>
                <td style="width: 150px">
                  <label class="checkbox">
                    <input v-model="accessGroup.select" type="checkbox" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal-app>
  </div>
</template>
  
  <script>
import NewUserForm from "../forms/NewUserForm.vue";
import ResetPasswordForm from "../forms/ResetPasswordForm.vue";
import { useListValue } from "../composables/listValue";
import { useListCrud } from "../composables/listCrud";
import UserService from "../services/UserService";
export default {
  components: { NewUserForm, ResetPasswordForm },
  data() {
    return {
      listValue: useListValue(null, null),
      service: new UserService(),
      listCrud: useListCrud(new UserService(), () => {
        return {};
      }),
      userAccessSelects: [],
      pagination: {
        page: 1,
        limit: 30,
        total: 0,
      },
      resetPasswordForm: {
        modalIsOpen: false,
        itemSelected: null,
      },
      newUserForm: {
        modalIsOpen: false,
        itemSelected: null,
      },
      accessGroupsForm: {
        modalIsOpen: false,
        itemSelected: null,
        accessGroups: [],
      },
      form: {
        modalIsOpen: false,
        itemSelected: null,
      },
      actions: [],
      headers: [
        { text: "Usuário", value: "userName" },
        { text: "E-mail", value: "email" },
      ],
      items: [],
    };
  },

  methods: {
    newUser() {
      this.newUserForm.itemSelected = {};
      this.newUserForm.modalIsOpen = true;
    },
    async saveNewUser() {
      const form = this.$refs.newUserFormRef;

      const isValid = await form.validate();

      if (!isValid) return;

      const model = this.newUserForm.itemSelected;
      const res = await this.service.register(
        model.userName,
        model.email,
        model.password,
        model.confirmPassword
      );
      if (res.status != 200) return;
      this.cancelNewUser();
      await this.listCrud.getItems();
    },
    cancelNewUser() {
      this.newUserForm.itemSelected = null;
      this.newUserForm.modalIsOpen = false;
    },
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },

    async newItem() {
      var res = await this.service.GetByIdOnUserAccessSelect();
      if (res.status != 200) return;
      this.userAccessSelects = res.data.data;
      this.listCrud.newItem();
    },

    async saveItem() {
      this.listCrud.itemSelected.userAccessSelect = this.userAccessSelects;
      await this.listCrud.saveItem();
    },

    async editItem(item) {
      var res = await this.service.GetByIdOnUserAccessSelect(item.id);
      if (res.status != 200) return;
      this.userAccessSelects = res.data.data.userAccessSelect;
      this.listCrud.editItem(item);
    },
    async saveAccessGroup() {
      const model = this.accessGroupsForm.itemSelected;
      const accessGroups = this.accessGroupsForm.accessGroups;
      const res = await this.service.updateAccessGroupsByUserId(
        model.id,
        accessGroups
      );
      if (res.status != 200) return;
      this.cancelAccessGroup();
    },
    cancelAccessGroup() {
      this.accessGroupsForm.itemSelected = null;
      this.accessGroupsForm.accessGroups = [];
      this.accessGroupsForm.modalIsOpen = false;
    },
    async getAccessGroupsByUserId(userId) {
      const res = await this.service.getAccessGroupsByUserId(userId);
      if (res.status != 200) return;
      this.accessGroupsForm.accessGroups = res.data.data;
    },
    async editAccessGroups(item) {
      this.accessGroupsForm.itemSelected = item;
      await this.getAccessGroupsByUserId(item.id);
      this.accessGroupsForm.modalIsOpen = true;
    },
    editResetPassword(item) {
      this.resetPasswordForm.itemSelected = item;
      this.resetPasswordForm.modalIsOpen = true;
    },
    async saveResetPassword() {
      const form = this.$refs.resetPasswordFormRef;
      const formIsValid = await form.validate();
      if (!formIsValid) return;

      const model = this.resetPasswordForm.itemSelected;
      const res = await this.service.resetPasswordByUserId(
        model.id,
        model.newPassword,
        model.confirmPassword
      );

      if (res.status != 200) return;

      this.cancelResetPassword();
    },
    cancelResetPassword() {
      this.resetPasswordForm.itemSelected = null;
      this.resetPasswordForm.modalIsOpen = false;
    },
  },

  async mounted() {
    await this.listCrud.getItems();
    this.actions = [
      {
        name: "Resetar Senha",
        color: "yellow",
        class: "has-text-link",
        icon: "fa-solid fa-pen",
        handle: this.editResetPassword,
      },
      {
        name: "Grupos de Acesso",
        color: "yellow",
        class: "has-text-link",
        icon: "fa-solid fa-pen",
        handle: this.editAccessGroups,
      },
      {
        name: "Deletar",
        color: "red",
        icon: "fa-solid fa-trash",
        handle: this.listCrud.deleteItem,
      },
    ];
  },
};
</script>
  
  <style>
</style>
<template>
  <div class="buttons" style="justify-content: space-evenly">
    <button v-permission:any="editAccess"
      data-tooltip="Editar"
      class="button is-small is-warning"
      @click="editItem(item)"
    >
      <span class="icon is-small">
        <i class="fa-solid fa-pen-to-square"></i>
      </span>
    </button>

    <button v-permission:any="deleteAccess"
      @click="deleteItem(item)"
      data-tooltip="Deletar"
      class="button is-small is-danger"
    >
      <span class="icon is-small">
        <i class="fa-solid fa-trash-can"></i>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    deleteAccess:{type:String},
    editAccess:{type:String}
  },
  methods: {
    editItem() {
      this.$emit("editClick", this.item);
    },
    deleteItem() {
      this.$emit("deleteClick", this.item);
    },
  },
};
</script>

<style>
</style>
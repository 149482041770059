<template>
  <div>
    <label class="label"> Filtro </label>
    <div class="filters">
      <label class="checkbox is-primary">
        <input
          type="checkbox"
          class="checkbox is-primary"
          v-model="filters.encerrado"
        />
        Encerrados
      </label>
    </div>
    <div class="btn-buscar-container">
      <button class="button is-info" @click="search">Buscar</button>
    </div>
    <table
      v-if="data && data.length"
      class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr>
          <th style="width: 150px">Status OP</th>
          <th>Codigo OP</th>
          <th>Descrição OP</th>
          <th>Código</th>
          <th>Razão Social</th>
          <th>Criado Por</th>
          <th>Criado Em</th>
          <th>Data Emissão</th>
          <th>Data Entrega</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(op, index) in data" :key="index">
          <td>
            <span
              :style="`display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: ${getColorStatus(op.status)};
                color: white;
                text-align: center;
                margin: 5px;`"
            >
              {{ op.status }}
            </span>
          </td>
          <td>{{ op.ordemProducao }}</td>
          <td>{{ op.descricao }}</td>
          <td>{{ op.orcamento }}</td>
          <td>{{ op.cliente }}</td>
          <td>{{ op.incluidoPor }}</td>
          <td>{{ dateToString(op.dataInclusao) }}</td>
          <td>{{ dateToString(op.dataEmissao) }}</td>
          <td>{{ dateToString(op.dataEntrega) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button
        class="button is-info is-light"
        @click="changePage(false)"
        :disabled="this.pagination.page === 1"
      >
        Anterior
      </button>
      <button
        :disabled="disabledNextPageComp"
        class="button is-info is-light"
        @click="changePage(true)"
      >
        Próxima
      </button>
    </div>
  </div>
</template>

<script>
import { getOrdemProducaoReport } from "../services/OrdemProducaoService";
import moment from "moment";
export default {
  data() {
    return {
      pagination: {
        page: 1,
        pages: 1,
        total: 0,
        limit: 30,
      },
      filters: {
        encerrado: false,
      },
      data: [],
    };
  },
  computed: {
    disabledNextPageComp() {
      return (
        this.pagination.page ==
        this.lastPage(this.pagination.limit, this.pagination.total)
      );
    },
  },
  methods: {
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    async search() {
      this.pagination.page = 1;
      await this.getItems();
    },
    async changePage(next = true) {
      if (!next && this.pagination.page == 1) return;

      this.pagination.page = next === true
        ? this.pagination.page + 1
        : this.pagination.page - 1;
      await this.getItems();
    },
    getColorStatus(status) {
      return status === "A PRODUZIR"
        ? "green"
        : status === "ENCERRADO"
        ? "red"
        : "orange";
    },
    dateToString(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getItems() {
      const res = await getOrdemProducaoReport(
        this.filters.encerrado,
        this.pagination.page,
        this.pagination.limit, 
        "DataInclusao",
        true
      );

      if (res.status != 200) return;

      this.data = res.data.data.data;
      this.pagination.total = res.data.data.total;
    },
  },
  async mounted() {
    await this.getItems();
  },
};
</script>

<style>
.btn-buscar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
.filters {
  margin-bottom: 14px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import UserService from "../services/UserService";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
import ComercialVendasReport from "../reports/comercial/ComercialVendasReport";
import OrdemProducaoReport from "../tenanties/STGraf/components/OrdemProducaoReport";
import AccessGroup from "../views/AccessGroup";
import UserAccess from "../views/UserAccess";
import User from "../views/User";
import Page404 from "../views/404.vue";
import NotAuthorized from "../views/NotAuthorized.vue";
import PedidoEntregas from "../views/PedidoEntregas.vue";
import RouteNew from "../views/RouteNew";
import RouteAll from "..//views/RouteAll"
import RouteLocal from "../tenanties/STGraf/views/RouteLocals.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: SignIn,
  },
  {
    path: "/not-authorized",
    name: "NotAuthorized",
    component: NotAuthorized,
  },
  {
    path: "/:pathMatch(.*)*",
    component: Page404,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      access: "authorize",
    },
    children: [
      {
        path: "/user",
        name: "User",
        component: User,
        meta: {
          access: "super|Usuários-Visualizar",
        },
      },
      {
        path: "/access-group/",
        name: "access-group",
        component: AccessGroup,
        meta: {
          access: "super|Grupos de Acesso-Visualizar",
        },
      },
      {
        path: "/access/",
        name: "access",
        component: UserAccess,
        meta: {
          access: "super",
        },
      },
      {
        path: "/ordem-producao/relatorio/ordem-producao",
        name: "ordem-producao-relatorio-ordem-producao",
        component: OrdemProducaoReport,
        meta: {
          access: "super|Relatório de Ordem Produção-Visualizar",
        },
      },
      {
        path: "/comercial/relatorio/vendas",
        name: "comercial-relatorio-vendas",
        component: ComercialVendasReport,
        meta: {
          access: "super|Relatório de Vendas-Visualizar",
        },
      },
      {
        path: "/pedidos/entregas",
        name: "PedidosEntregas",
        component: PedidoEntregas,
        meta: {
          access: "super|Pedidos Entregas-Visualizar",
        },
        
      },
      {
        path: "/pedidos/entregas/route/new",
        name: "RouteNew",
        component: RouteNew,
        meta: {
          access: "super|Rotas de Entrega-Visualizar",
        },
        
      },
      {
        path: "/pedidos/entregas/route/all",
        name: "RouteAll",
        component: RouteAll,
        meta: {
          access: "super|Rotas de Entrega-Visualizar",
        },
        
      },{

        path: "/route/locals",
        name: "RouteLocal",
        component: RouteLocal,
        meta: {
          access: "super|Locais de Rota-Visualizar",
        },
        
      },
    ],
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userService = new UserService();

  const userToken = userService.getUserToken();

  if (to.meta && to.meta.access) {
    if (!userToken) next("/login");
    else next();
    return;
  } else next();
});

export default router;

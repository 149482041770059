<template>
  <div>
    <VendedorVendasReport/>
  </div>
</template>

<script>
import VendedorVendasReport from "../../tenanties/STGraf/components/VendedorVendasReport";
import moment from "moment";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { getMonthByNumber } from "../../util/dateUtil";
export default {
  components: { VendedorVendasReport},
  data() {
    return {
      ops: [
        {
          status: "A PRODUZIR",
          ordemProducao: "135819",
          orcamento: "135819P",
          descricao: "JJR - PASTA COM BOLSA",
          cliente: "JJR INCORPORADORA E CONSTRUTORA",
          incluidoPor: "THIAGO.B",
          dataInclusao: "2022-09-02T16:11:57.047",
          dataEmissao: "2022-09-02T00:00:00",
          dataEntrega: "2022-09-15T00:00:00",
        },
        {
          status: "A PRODUZIR",
          ordemProducao: "135809",
          orcamento: "135809P",
          descricao: "COSTELARIA CHARMOSA - KIT BANDEJAS DE ALIMENTOS",
          cliente: "COSTELARIA CHARMOSA DE ITAPETININGA",
          incluidoPor: "OSMAR.CR",
          dataInclusao: "2022-07-06T09:15:38.837",
          dataEmissao: "2022-07-06T00:00:00",
          dataEntrega: "2022-07-20T00:00:00",
        },
        {
          status: "A PRODUZIR",
          ordemProducao: "135770",
          orcamento: "135770P",
          descricao:
            "MAGGI CONSORCIO - ENVELOPES POS CONTEMPLAÇÃO ( NOTA DE VENDA PELA PRIMA )",
          cliente: "MAGGI ADMINISTRADORA DE CONSORCIOS LTDA",
          incluidoPor: "OSMAR.CR",
          dataInclusao: "2022-07-13T16:36:11.48",
          dataEmissao: "2022-07-13T00:00:00",
          dataEntrega: "2022-07-25T00:00:00",
        },
        {
          status: "A PRODUZIR",
          ordemProducao: "135730",
          orcamento: "135730P",
          descricao: "KOPPERT - BANDEJA (Nota fical de Produto - PRIMA IDEA)",
          cliente: "KOPPERT DO BRASIL",
          incluidoPor: "LUCIANA.",
          dataInclusao: "2022-08-19T12:54:21.92",
          dataEmissao: "2022-08-19T00:00:00",
          dataEntrega: "2022-09-19T00:00:00",
        },
      ],
      chart: null,
      labels: [],
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "My First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      },
      config: {
        type: "bar",
        data: this.data,
        options: {
          indexAxis: "y",
        },
      },
    };
  },
  methods: {
    dateToString(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    createData() {
      const dataMock = [
        {
          mes: 12,
          ano: 2015,
          mesAno: "12/2015",
          vendedor: "REGRAPH",
          valorTotal: 15758.5,
          comissao: 1127.9999999999995,
        },
        {
          mes: 1,
          ano: 2016,
          mesAno: "01/2016",
          vendedor: "REGRAPH",
          valorTotal: 25189.0875,
          comissao: 1386.5225664089594,
        },
        {
          mes: 3,
          ano: 2016,
          mesAno: "03/2016",
          vendedor: "REGRAPH",
          valorTotal: 116762.63666666667,
          comissao: 6291.348981951389,
        },
      ];

      dataMock.forEach((d) => {
        d.mesNome = getMonthByNumber(d.mes);
        d.mesNomeEAno = `${d.mesNome.substring(0, 3)}/${d.ano}`;
      });

      this.labels = dataMock.map((d) => {
        return d.mesNomeEAno;
      });

      this.data.labels = this.labels;
      this.data.datasets[0].data = dataMock.map((d) => {
        return d.valorTotal;
      });

      this.config.data = this.data;
    },
  },
  mounted() {
    this.createData();
    // const ctx = this.$refs.chart;
    // const config = JSON.parse(JSON.stringify(this.config));
    // this.chart = new Chart(ctx, config);
    // console.log(this.chart)
  },
};
</script>

<style>
</style>
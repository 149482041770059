import apiClient from "../../../api/apiClient";

const path = "OrdemProducao";

export function getOrdemProducaoReport(
  encerrado,
  page,
  limit,
  sortBy,
  sortDesc
) {
  const params = { encerrado, page, limit, sortBy, sortDesc };
  return apiClient.get(`${path}/report/op`, { params });
}

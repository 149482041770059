<template>
  <div class="container-page">
    <div class="is-size-2 mb-3">Não Autorizado</div>
    <button class="button is-primary" @click="$router.push('/')">Voltar</button>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style>
.container-page {
  padding: 30px;
}
</style>
import { useConfirmStore } from "../stores/confirmStore";

export function useConfirm() {
  const confirmStore = useConfirmStore();

  function confirmation(text) {
    return confirmStore.confirmation(text);
  }

  return { confirmation };
}

import apiClient from "../../../api/apiClient";

const path = "VendedorReport";

export function getVendasReportByDateAndVendedorNome(
  dtStart,
  dtEnd,
  nomeVendedor,
  page,
  limit,
  sortBy,
  sortDesc
) {
  const body = {
    dtStart,
    dtEnd,
    nomeVendedor,
    page,
    limit,
    sortBy,
    sortDesc,
  };
  return apiClient.post(`${path}/vendas`, body);
}

export function getVendasReportByDateAndVendedorNomeGroupMonthAndYear(
  dtStart,
  dtEnd,
  nomeVendedor
) {
  const body = {
    dtStart,
    dtEnd,
    nomeVendedor,
  };
  return apiClient.post(`${path}/vendas-agrupado`, body);
}


export function getVendedoresNome(){
  return apiClient.get(`${path}/vendedores`);
}
<template>
  <div>
    <div class="is-size-2 mb-3">Grupos de Acesso</div>
    <button class="button is-primary mb-5" @click="newItem">Novo</button>
    <table-app
      :headers="headers"
      :items="listCrud.items"
      :actions="actions"
      :rowsLimit="listCrud.pagination.limit"
      :page="listCrud.pagination.page"
      :itemsTotal="listCrud.pagination.total"
      @search="listCrud.getItemsSearch"
    >
    </table-app>
    <modal-app
      v-model="listCrud.modalIsOpen"
      title="Acesso"
      @confirm="saveItem"
      @cancel="listCrud.cancelItem"
    >
      <div v-if="listCrud.itemSelected">
        <Form class="mb-5">
          <div class="field">
            <label class="label">Nome</label>
            <div class="control">
              <Field
                class="input"
                v-model="listCrud.itemSelected.name"
                name="modulo"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="modulo"
              ></ErrorMessage>
            </div>
          </div>
        </Form>

        <div v-if="userAccessSelects && userAccessSelects.length">
          <div
          
          >
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Módulo</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(userAccessSelect, index) in userAccessSelects" :key="index">
                  <td>{{userAccessSelect.module}}</td>
                  <td style="width:150px">
                    <label class="checkbox">
                <input v-model="userAccessSelect.allowed" type="checkbox" />
                {{ userAccessSelect.type }}
              </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal-app>
  </div>
</template>

<script>
import { useListValue } from "../composables/listValue";
import { useListCrud } from "../composables/listCrud";
import { Form, Field, ErrorMessage } from "vee-validate";
import AccessGroupService from "../services/AccessGroupService";
export default {
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      listValue: useListValue(null, null),
      service: new AccessGroupService(),
      listCrud: useListCrud(new AccessGroupService(), () => {
        return {};
      }),
      userAccessSelects: [],
      pagination: {
        page: 1,
        limit: 30,
        total: 0,
      },
      form: {
        modalIsOpen: false,
        itemSelected: null,
      },
      actions: [],
      headers: [{ text: "Nome", value: "name" }],
      items: [],
    };
  },

  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },

    async newItem() {
      var res = await this.service.GetByIdOnUserAccessSelect();
      if (res.status != 200) return;
      this.userAccessSelects = res.data.data;
      this.listCrud.newItem();
    },

    async saveItem(){
      this.listCrud.itemSelected.userAccessSelect = this.userAccessSelects
      await this.listCrud.saveItem();
    },

    async editItem(item) {
      var res = await this.service.GetByIdOnUserAccessSelect(item.id);
      if (res.status != 200) return;
      this.userAccessSelects = res.data.data.userAccessSelect;
      this.listCrud.editItem(item);
    },
  },

  async mounted() {
    await this.listCrud.getItems();
    this.actions = [
      {
        name: "Editar",
        color: "yellow",
        class: "has-text-link",
        icon: "fa-solid fa-pen",
        handle: this.editItem,
      },
      { name: "Deletar", color: "red", icon: "fa-solid fa-trash", handle: this.listCrud.deleteItem },
    ];
  },
};
</script>

<style>
</style>
import { ref } from "vue";
import {useConfirm} from "../composables/confirm"

export function useListValue(itemList,defaultItemValue, onUpdateMethod) {
  const {confirmation} = useConfirm();
  const items = ref(itemList || []);
  const itemSelected = ref();
  const indexSelected = ref();
  const modalIsOpen = ref(false);

  function newItem() {
    clearItem();
    if (defaultItemValue) itemSelected.value = defaultItemValue();
    else itemSelected.value = "";
    modalIsOpen.value = true;
  }

  function editItem(item, index) {
    indexSelected.value = index;

    if (typeof item == "string") {
      itemSelected.value = item.toString();
    } else {
      let itemTemp = null;
      Object.assign(itemTemp, item);
      itemSelected.value = itemTemp;
    }
    modalIsOpen.value = true;
  }

  function clearItem() {
    indexSelected.value = null;
    itemSelected.value = null;
  }

  async function saveItem() {
    const resConfirm = await confirmation();
    if(!resConfirm) return;

    const list = [...items.value];
    if (indexSelected.value != null && indexSelected.value >= 0)
      list[indexSelected.value] = itemSelected.value;
    else list.push(itemSelected.value);

    items.value = list;
    modalIsOpen.value = false;

    executeUpdateMethodHandle(list);
    clearItem();
  }

  async function deleteItem(index) {
    const resConfirm = await confirmation();
    if(!resConfirm) return;
    let list = [...items.value];
    list.splice(index, 1);
    items.value = list;
    executeUpdateMethodHandle(list);
  }

  function cancelItem() {
    clearItem();
    modalIsOpen.value = false;
  }

  function setItems(arrayList) {
    items.value = arrayList;
  }

  function executeUpdateMethodHandle(items) {
    if (onUpdateMethod) onUpdateMethod(items);
  }

  return {
    items,
    itemSelected,
    indexSelected,
    modalIsOpen,
    newItem,
    editItem,
    saveItem,
    deleteItem,
    cancelItem,
    setItems,
  };
}

<template>
  <div class="is-size-3 has-text-weight-bold mb-5">Pedidos Roteirizados</div>

  <div class="filters">
    <div class="field">
      <label class="label">Data Inicio Partida</label>
      <div class="control">
        <input v-model="filter.startDate" class="input" type="date" placeholder="Data Inicio" />
      </div>
    </div>

    <div class="field">
      <label class="label">Data Fim Partida</label>
      <div class="control">
        <input v-model="filter.endDate" class="input" type="date" placeholder="Data Fim" />
      </div>
    </div>
    <div class="field">
      <label class="label">Cliente</label>
      <div class="select">
        <select v-model="filter.clienteId">
          <option selected value="">Todos</option>
          >
          <option v-for="(cliente, index) in clientes" :key="index" :value="cliente.id">
            {{ cliente.cliente }}
          </option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label">OS</label>
      <div class="control">
        <input v-model="filter.os" class="input" type="text" placeholder="000001" />
      </div>
    </div>
  </div>

  <div style="display: flex; justify-content: flex-end">
    <button class="button is-primary mb-5" @click="search">Filtrar</button>
  </div>
  <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th>Data Partida Rota</th>
        <th>Data Inserção Rota</th>
        <th>Cód. Rota</th>
        <th>Status Rota</th>
        <th>Status Pedido</th>
        <th>OS</th>
        <th>Observação</th>
        <th>Descrição</th>
        <th>Cliente</th>
        <th>Itens</th>
        <th style="width: 130px">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ dateFormat(item.date) }}</td>
        <td>{{ dateFormat(item.createAt) }}</td>
        <td>{{ item.routeCode }}</td>
        <td>{{ item.routeStatus }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.numOrdem }}</td>
        <td>{{ item.obs }}</td>
        <td>{{ item.description }}</td>
        <td>{{ item.nome }}</td>
        <td>{{ item.descricaoNota }}</td>
        <td>
          <div class="buttons" style="justify-content: space-evenly">
            <button v-permission:any="'super|Rotas de Entrega-Editar'" data-tooltip="Finalizar Rota"
              class="button is-small is-primary" @click="finishRoute(item)">
              <span class="icon is-small">
                <i class="fa-solid fa-check"></i>
              </span>
            </button>
            <button v-permission:any="'super|Rotas de Entrega-Editar'" data-tooltip="Editar Rota"
              class="button is-small is-warning" @click="montarTabelaPorId(item.pedidoEntregaRouteId)">
              <span class="icon is-small">
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
            </button>

            <button v-permission:any="'super|Rotas de Entrega-Deletar'" data-tooltip="Deletar Rota"
              class="button is-small is-danger" @click="deleteRoute(item.pedidoEntregaRouteId)">
              <span class="icon is-small">
                <i class="fa-solid fa-trash-can"></i>
              </span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button :disabled="disabledLastPageComp" class="button is-info is-light" @click="changePage(false, true, false)">
      <span class="icon">
        <i class="fa-solid fa-angles-left"></i>
      </span>
    </button>
    <button class="button is-info is-light" @click="changePage(false, false, false)" :disabled="disabledLastPageComp">
      Anterior
    </button>
    <button :disabled="disabledNextPageComp" class="button is-info is-light" @click="changePage(false, false, true)">
      Próxima
    </button>
    <button :disabled="disabledNextPageComp" class="button is-info is-light" @click="changePage(true, false, true)">
      <span class="icon">
        <i class="fa-solid fa-angles-right"></i>
      </span>
    </button>
  </div>

  <ModalApp title="Editar rota" v-model="modalVisivel" :width="1500" @confirm="updateRoute">
    <div v-if="routeById != null">
      <div>
        <div class="field">
          <span>Codigo</span>
          <div class="control">
            <input class="input is-focused" type="text" v-model="routeById.code" disabled />
          </div>
        </div>

        <div class="field">
          <span>Descrição</span>
          <div class="control">
            <input class="input is-focused" type="text" v-model="routeById.description" disabled />
          </div>
        </div>

        <div class="field">
          <span>Data Rota</span>
          <div class="control">
            <input v-model="routeById.date" class="input" type="date" />
          </div>
        </div>

        <div class="field">
          <span>Data Criação</span>
          <div class="control">
            <input class="input is-focused" type="text" :value="dateFormat(routeById.createAt)" disabled />
          </div>
        </div>

        <div class="field">
          <span>Observação</span>
          <div class="control">
            <input class="input is-focused" type="text" v-model="routeById.obs" />
          </div>
        </div>
      </div>

      <div class="is-size-3 has-text-weight-bold mb-5 mt-5">Pedidos da rota</div>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Seq</th>
            <th>Pedido</th>
            <th>Local de Rota</th>
            <th>Status</th>
            <th>Data Partida Rota</th>
            <th>Data Inserção Rota</th>
            <th>Observação</th>
            <th>Descrição</th>
            <th>Nome</th>
            <th>Itens</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemsRouteById" :key="index">
            <td>{{ item.seq }}</td>
            <td>{{ item.numPedido ? item.numPedido : "Local" }}</td>
            <td>{{ item.routeLocalId ? "Sim" : "Não" }}</td>
            <td>{{ item.status ? item.status : "-" }}</td>
            <td>{{ dateFormat(item.date) }}</td>
            <td>{{ dateFormat(item.createAt) }}</td>
            <td>{{ item.obs ? item.obs : "-" }}</td>
            <td>{{ item.description ? item.description : "-" }}</td>
            <td>{{ item.nome ? item.nome : item.routeLocalDescricao }}</td>
            <td>{{ item.descricaoNota }}</td>

            <td>
              <div class="buttons" style="justify-content: space-evenly">
                <div v-permission:any="'super|Rotas de Entrega-Editar'">
                  <button :disabled="item.status == 'Finalizado' || item.routeLocalId != null"
                    data-tooltip="Finalizar Pedido" class="button is-small is-primary has-tooltip-left"
                    @click="updateToFinalizadoStatus(item)">
                    <span class="icon is-small">
                      <i class="fa-solid fa-check"></i>
                    </span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ModalApp>
</template>
  
<script>
import moment from "moment";
import PedidoEntregaRouteService from "../services/PedidoEntregaRouteService";
import PedidoEntregaService from "../services/PedidoEntregaService";
import ModalApp from "../components/ModalApp.vue";
import { useConfirmStore } from "../stores/confirmStore";
export default {
  components: { ModalApp },
  data: () => {
    return {
      confirmStore: useConfirmStore(),
      modalVisivel: false,
      pedidoEntregaRouteService: new PedidoEntregaRouteService(),
      pedidoEntregaService: new PedidoEntregaService(),
      items: [],
      routeById: null,
      itemsRouteById: [],
      page: 1,
      limit: 30,
      total: 0,
      clientes: [],
      selected: [],
      filter: {
        startDate: null,
        endDate: null,
        clienteId: "",
        os: "",
      },
    };
  },
  watch: {
    async page() {
      await this.getAll();
      window.scrollTo(0, 0);
    },
  },
  computed: {
    disabledLastPageComp() {
      return this.page === 1;
    },
    disabledNextPageComp() {
      return this.page == this.lastPage(this.limit, this.total);
    },
  },
  methods: {
    async updateToFinalizadoStatus(item) {
      const resultConfirm = await this.confirmStore.confirmation(
        "Tem certeza que deseja finalizar este pedido?"
      );
      if (!resultConfirm) return;


      const itemRequest = {
        idEntrega: item.idEntrega,
        idItemEntrega: item.idItemEntrega,
        status: "Finalizado",
      };
      const result = await this.pedidoEntregaService.updateStatus(itemRequest);
      if (result.status != 200) return;

      await this.montarTabelaPorId(item.pedidoEntregaRouteId);
    },
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    async changePage(last, first, next = true) {
      if (last === true) {
        this.page = this.lastPage(this.limit, this.total);
        return;
      }
      if (first === true) {
        this.page = 1;
        return;
      }
      if (!next && this.page == 1) return;
      this.page = next === true ? this.page + 1 : this.page - 1;
    },
    async finishRoute(item) {
      const result = await this.confirmStore.confirmation(
        "Tem certeza que deseja finalizar a rota?"
      );
      if (!result) return;
      await this.pedidoEntregaRouteService.updateStatus(
        item.pedidoEntregaRouteId,
        2
      );
      this.getAll();
    },
    async deleteRoute(id) {
      const result = await this.confirmStore.confirmation(
        "Tem certeza que deseja deletar a rota?"
      );
      if (!result) return;

      if (id.value != 0) {
        await this.pedidoEntregaRouteService.remove(id);
        this.getAll();
      } else {
        return;
      }
    },
    async updateRoute() {

      const routeUpdate = {
        ...this.routeById,
      };
      delete routeUpdate.routeItems;
      await this.pedidoEntregaRouteService.update(routeUpdate);
      this.fecharModal();

    },
    formatDateRoute(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async montarTabelaPorId(id) {
      try {
        const result = await this.pedidoEntregaRouteService.getById(id);

        if (result.status === 200) {
          this.routeById = result.data.data;
          this.routeById.date = this.formatDateRoute(this.routeById.date);
          this.itemsRouteById = this.routeById.routeItems;
          this.mostrarModal();
        } else {
          console.error("API request failed with status code:", result.status);
          console.log("erro no metodo montarTabelaPorId");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    },
    mostrarModal() {
      this.modalVisivel = true;
    },
    fecharModal() {
      this.modalVisivel = false;
      this.itemsFiltred = [];
    },
    setStartAndEndDate() {
      const startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      this.filter.startDate = moment(startDate).format("YYYY-MM-DD");
      this.filter.endDate = moment(endDate).format("YYYY-MM-DD");
    },
    async search() {
      this.page = 1;
      await this.getAll();
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YY");
    },
    async getAll() {
      try {
        const result = await this.pedidoEntregaRouteService.getAllRoutes(
          this.page,
          this.limit,
          this.filter
        );

        if (result.status === 200) {
          this.items = result.data.data.data;
          this.total = result.data.data.total;
        } else {
          console.error("API request failed with status code:", result.status);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    },

    async getClientes() {
      var result = await this.pedidoEntregaService.getClientes();
      if (result.status != 200) return;
      this.clientes = result.data.data;
    },
  },

  async mounted() {
    this.setStartAndEndDate();
    await this.getClientes();
    await this.getAll();
  },
};
</script>
<style>
.select-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>

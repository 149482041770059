import { defineStore } from "pinia";

export const useConfirmStore = defineStore("confirm", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      isConfirm: false,
      text: null,
      confirmResolve: null,
      isAlert:false,
    };
  },
  actions: {
    alert(text) {
      this.setText(text);
      this.isConfirm = true;
      this.isAlert = true;
      return new Promise((res) => {
        this.confirmResolve = res;
      });
    },
    confirmation(text) {
      this.setText(text);
      this.isConfirm = true;
      return new Promise((res) => {
        this.confirmResolve = res;
      });
    },
    setText(text) {
      this.text = text;
    },
    closeConfirmation() {
      this.isConfirm = false;
      this.isAlert = false;
      this.text = null;
      this.confirmResolve = null;
    },
    okConfirmation() {
      this.confirmResolve(true);
      this.closeConfirmation();
    },
    cancelConfirmation() {
      this.confirmResolve(false);
      this.closeConfirmation();
    },
  },
});

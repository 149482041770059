<template>
  <div id="modalConfirm" :class="`modal my-modal ${isActiveClassComp}`">
    <div class="modal-background"></div>
    <div class="modal-card" style="width: 250px">
      <header class="modal-card-head">
        <p class="modal-card-title">Atenção</p>
      </header>
      <section class="modal-card-body">{{ text || "Tem certeza?" }}</section>

      <footer class="modal-card-foot is-justify-content-right">
        <slot name="buttons">
          <button v-if="!isAlert" class="button" @click="cancelConfirmation">
            Cancelar
          </button>
          <button class="button is-success" @click="okConfirmation">
            {{ lagelBtn() }}
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>

  <script setup>
import { computed } from "vue";
import { useConfirmStore } from "../stores/confirmStore";
import { storeToRefs } from "pinia";

const confirmStore = useConfirmStore();

const { isConfirm, text, isAlert } = storeToRefs(confirmStore);
const { okConfirmation, cancelConfirmation } = confirmStore;

const isActiveClassComp = computed(() => {
  return isConfirm.value === true ? "is-active" : "";
});

function lagelBtn() {
  return isAlert === true ? "Ok" : "Confirmar";
}
</script>
  
  <style>
.my-modal {
  z-index: 1000;
}
</style>
<template>
  <div>
    <div class="field has-addons">
      <div class="control" style="width: 100%">
        <input
          class="input"
          style="width: 100%"
          type="text"
          placeholder="Pesquisar"
          v-model="search"
        />
      </div>
      <div class="control">
        <a class="button is-info" @click="searchOnClick"> Buscar </a>
      </div>
    </div>
    <table
      v-if="items && items.length"
      class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr>
          <th
            v-for="(header, index) in headersComp"
            :key="index"
            :style="actionRowStyle(header)"
          >
            <span v-if="header.value == 'actions'">
              {{ header.text }}
            </span>

            <span v-else>{{ header.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td
            v-for="(header, headerIndex) in headersComp"
            :key="headerIndex"
            :style="actionRowStyle(header)"
          >
            <slot :name="`td-${header.value}`">
              <span v-if="header.value == 'actions'">
                <div
                  :id="`more-${index}`"
                  :ref="`more-${index}`"
                  :class="`dropdown ${moreIsActive(item)}`"
                >
                  <div class="dropdown-trigger dropdown-click">
                    <button
                      class="button is-small dropdown-click"
                      @click="moreOnClick(index)"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span class="icon is-large dropdown-click">
                        <i
                          class="fa-sharp fa-solid fa-ellipsis dropdown-click"
                        ></i>
                      </span>
                    </button>
                  </div>
                  <div
                    class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                    style="left: -150px"
                  >
                    <div class="dropdown-content">
                      <a
                        v-for="(action, actionIndex) in actions"
                        :key="actionIndex"
                        class="dropdown-item"
                        @click="actionOnClick(index, item, action)"
                      >
                        <span
                          :class="`icon ${action.class}`"
                          :style="`color:${action.color};margin-right:15px;`"
                        >
                          <i :class="action.icon"></i>
                        </span>
                        <span style="font-weight: bold">{{ action.name }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </span>

              <span v-else> {{ item[header.value] }}</span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button
        class="button is-info is-light"
        @click="changePage(false)"
        :disabled="disabledLastPageComp"
      >
        Anterior
      </button>
      <button
        :disabled="disabledNextPageComp"
        class="button is-info is-light"
        @click="changePage(true)"
      >
        Próxima
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    items: Array,
    actions: Array,
    pagesTotal: { type: Number, default: 1 },
    rowsLimit: { type: Number, default: 30 },
    page: { type: Number, default: 1 },
    itemsTotal: { type: Number, default: 0 },
  },
  data() {
    return {
      search: "",
      moreDropdownOpenIndex: null,
    };
  },
  computed: {
    totalPageComp(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    headersComp() {
      if (!this.actions || this.actions.length == 0) return this.headers;
      const actionHeader = { text: "Ações", value: "actions" };
      return [...this.headers, actionHeader];
    },
    disabledLastPageComp() {
      return this.page === 1;
    },
    disabledNextPageComp() {
      return this.page == this.lastPage(this.rowsLimit, this.itemsTotal);
    },
  },
  methods: {
    moreIsActive(item) {
      return item.isActive  ? "is-active" : "";
    },
    actionRowStyle(header) {
      return header.value == "actions"
        ? "width: 100px; text-align: center"
        : "";
    },
    dropDownClickOutEvent(event) {
      if (event.target.classList.contains("dropdown-click")) return;
      const dropDownEls = document.getElementsByClassName("dropdown is-active");
      if (dropDownEls.length > 0) dropDownEls[0].classList.remove("is-active");
    },
    actionOnClick(index, item, action) {
      this.moreOnClick(index);
      if (action.handle) action.handle(item);
    },
    removeALLDropdownOpened() {
      const dropDownEls = document.getElementsByClassName("dropdown is-active");
      for (let index = 0; index < dropDownEls.length; index++) {
        const el = dropDownEls[index];
        el.classList.remove("is-active");
      }
    },
    moreOnClick(index) {
      this.removeALLDropdownOpened();

      const item = this.items[index];
      if (!item) return;

      item.isActive = !item.isActive;
      // const moreRef = this.$refs[`more-${index}`][0];
      // if (item.isActive) moreRef.classList.add("is-active");
      // else moreRef.classList.remove("is-active");
    },
    searchOnClick() {
      this.$emit("search", this.search);
    },
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    async changePage(next = true) {
      if (!next && this.pagination.page == 1) return;
      this.$emit("page-change", next === true ? this.page + 1 : this.page - 1);
    },
  },
  mounted() {
    document.addEventListener("click", this.dropDownClickOutEvent);
  },
  unmounted() {
    document.removeEventListener("click", this.dropDownClickOutEvent);
  },
};
</script>

<style>
</style>
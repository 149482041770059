<template>
  <modal-confirm-app ref="modalConfirmRef"></modal-confirm-app>
  <notifications position="top center" />
  <LoadingFull :modelValue="loading" />
  <router-view />
</template>


<script setup>


import LoadingFull from "./components/LoadingFull.vue";

import { storeToRefs } from "pinia";
import { ref, provide } from "vue";
import { useMainStore } from "./stores/mainStore";


const { loading } = storeToRefs(useMainStore());

const modalConfirmRef = ref();

provide("modal-confirm", modalConfirmRef);

document.title = 'Ext'
</script>


<style lang="scss">
* {
  margin: 0px;
  padding: 0px;


}
</style>


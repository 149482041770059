<template>
  <div>
    <button class="button is-primary mb-5" @click="$emit('new-item')">
      Novo
    </button>
    <table
      v-if="modelValue && modelValue.length"
      class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr v-if="itemText">
          <th>{{ itemText }}</th>
          <th style="width: 100px; text-align: center">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in modelValue" :key="index">
          <td v-if="!itemValue">{{ item }}</td>
          <td v-else>{{ item[itemValue] }}</td>
          <td>
            <p class="buttons is-justify-content-center">
              <button
                class="button is-warning is-small"
                @click="$emit('edit-item', item, index)"
              >
                <span class="icon is-small">
                  <i class="fa-solid fa-pen"></i>
                </span>
              </button>
              <button
                class="button is-danger is-small"
                @click="$emit('delete-item', index)"
              >
                <span class="icon is-small">
                  <i class="fa-solid fa-trash"></i>
                </span>
              </button>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <modal-app
      v-model="form.modalIsOpen"
      :title="title"
      @cancel="cancelItem"
      @confirm="saveItem"
    >
      <slot name="form" :item="form.itemSelected"></slot>
    </modal-app>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    itemText: String,
    itemValue: String,
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultItemValue: Function,
  },
  data() {
    return {
      form: {
        modalIsOpen: false,
        itemSelected: null,
        indexSelected: null,
      },
    };
  },
  methods: {
    newItem() {
      this.clearItem();
      if (this.defaultItemValue)
        this.form.itemSelected = this.defaultItemValue();
      else this.form.itemSelected = "";

      this.form.modalIsOpen = true;
    },

    editItem(item, index) {
      this.form.indexSelected = index;

      if (typeof item == "string") {
        this.form.itemSelected = item.toString();
      } else {
        let itemTemp = null;
        Object.assign(itemTemp, item);
        this.form.itemSelected = itemTemp;
      }
      this.form.modalIsOpen = true;
    },

    clearItem() {
      this.form.indexSelected = null;
      this.form.itemSelected = null;
    },
    saveItem() {
      const list = [...this.modelValue];
      if (this.form.indexSelected)
        list[this.form.indexSelected] = this.form.itemSelected;
      else list.push(this.form.indexSelected);
      this.form.modalIsOpen = false;
      this.sendUpdateEvent(list);
      this.clearItem();
    },
    deleteItem(index) {
      let list = [...this.modelValue];
      list.splice(index, 1);
      this.sendUpdateEvent(list);
    },
    cancelItem() {
      this.clearItem();
      this.form.modalIsOpen = false;
    },
    sendUpdateEvent(list) {
      this.$emit("update:modelValue", list);
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="page-container">
    <div class="form">
    <Form ref="form" >
      <input-app
        label="Email"
        v-model="model.username"
        @keyup.enter="sendSignIn"
        :rules="isRequired"
      ></input-app>
      <input-app
        label="Senha"
        v-model="model.password"
        @keyup.enter="sendSignIn"
        type="password"
        :rules="isRequired"
      ></input-app>
    </Form>
    <div class="buttom-signin">
      <button class="button is-primary" @click="sendSignIn">Entrar</button>
    </div>
  </div>
    <div class="form-img">
      <!-- <img class="form-img-img" src="../assets/sign-img.jpg" /> -->
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  data() {
    return {
      service: new UserService(),
      model: { username: null, password: null },
    };
  },
  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },
    async sendSignIn() {
      const form = this.$refs.form;
      const formValidation = await form.validate();
      if (!formValidation.valid) return;

      const res = await this.service.signIn(
        this.model.username,
        this.model.password
      );
      if (!res) return;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.form-img-img {
  width: 100%;
  height: auto;
}
.page-container {
  background-image: url("../assets/sign-img.jpg");

  background-size: cover;
  display: flex;
  width: 100%;
  height: 100vh;
}
.form {
  background-color: white;
  gap: 10px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}
.form-img {
  width: 60%;
}
.buttom-signin {
  text-align: end;
}
</style>
import ServiceBase from "./ServiceBase";
import { useMainStore } from "../stores/mainStore";

const USERPARAMSKEY = "user-params";


class UserService extends ServiceBase {
  constructor() {
    super("user");
  }

  register(username, email, password, confirmPassword) {
    return this.client.post(`${this.path}/register`, {
      username,
      email,
      password,
      confirmPassword,
    });
  }

  resetPasswordByUserId(userId, newPassword, confirmPassword) {
    return this.client.post(`${this.path}/password-reset`, {
      userId,
      newPassword,
      confirmPassword,
    });
  }

  updatePasswordByUserId(userId,oldPassword, newPassword, confirmPassword) {
    return this.client.post(`${this.path}/password-update`, {
      userId,
      oldPassword,
      newPassword,
      confirmPassword,
    });
  }


  getAccessGroupsByUserId(userId) {
    return this.client.get(`${this.path}/${userId}/access-group`);
  }
  updateAccessGroupsByUserId(userId, accessGroups) {
    return this.client.post(`${this.path}/${userId}/access-group`, accessGroups);
  }

  setUserPermissions(app){
    const claims = this.getUserClaims();
    const isSuper = claims.filter(claim => claim.type.toUpperCase() === "SUPER").length > 0;
    const roles = claims.map(claim => {return `${claim.type}-${claim.value}`});
    if(isSuper) roles.push("super");


    const gates = app.appContext.config.globalProperties.$gates;

    gates.setPermissions(roles);
  }

  async signIn(username, password) {
    const mainStore = useMainStore();
    const res = await this.client.post("/token", { username, password });
    if (res.status != 200) return false;

    const userparams = res.data.data;
    localStorage.setItem(USERPARAMSKEY, JSON.stringify(userparams));
    mainStore.setUser(userparams)

    return true;
  }


  getUserToken(){
    const mainStore = useMainStore();
    if(!mainStore.user) return null;
    return mainStore.user.tokenAccess;
  }

  getUserClaims(){
    const mainStore = useMainStore();
    if(!mainStore.user) return [];
    return mainStore.user.claims.map(claim => {return {type:claim.type,value:claim.value}});
  }

  getUserId(){
    const claims = this.getUserClaims();
    const claim = claims.find(claim => claim.type == "userid");
    if(claim) return claim.value;
    else return null;
  }

  getUserName(){
    const mainStore = useMainStore();
    if(!mainStore.user) return null;
    return mainStore.user.username;
  }

  logout() {
    const mainStore = useMainStore();
    localStorage.removeItem(USERPARAMSKEY);
    mainStore.setUser(null);
    return true;
  }
}

export default UserService;

<template>
  <div>
    <BarChart v-bind="$attrs" :chartData="chartData"></BarChart>
  </div>
</template>
  
  <script>
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { getMonthByNumber } from "../../../../util/dateUtil";
export default {
  components: { BarChart },
  props: { data: Array },
  data() {
    return {
      chart: null,
      labels: [],
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: "Análise de Vendas",
            data: [],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      },
      config: {
        type: "bar",
        data: this.chartData,
        options: {
          indexAxis: "y",
        },
      },
    };
  },
  watch: {
    data() {
      this.createData();
    },
  },
  methods: {
    createData() {
      if (!this.data || this.data.length == 0) return;
      this.data.forEach((d) => {
        d.mesNome = getMonthByNumber(d.mes);
        d.mesNomeEAno = `${d.mesNome.substring(0, 3)}/${d.ano}`;
      });

      this.labels = this.data.map((d) => {
        return d.mesNomeEAno;
      });

      this.chartData.labels = this.labels;
      this.chartData.datasets[0].data = this.data.map((d) => {
        return d.valorTotal;
      });

      this.config.data = this.data;
    },
  },
  mounted() {
    this.createData();
  },
};
</script>
  
  <style>
</style>
<template>
  <div>
    <div class="is-size-3 has-text-weight-bold mb-5">Locais de Rota</div>

    <button class="button is-primary mb-5" @click="newItem">Nova Rota</button>

    <div class="field mb-5">
      <label class="label">Pesquisar</label>
      <div class="control">
        <input v-model="search" class="input" type="text" placeholder="Pesquise aqui..." @keyup.enter="searchItems" />
      </div>
    </div>

    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Descrição</th>
          <th>Logradouro</th>
          <th>Bairro</th>
          <th>Cidade</th>
          <th style="width: 130px">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.descricao }}</td>
          <td>{{ item.logradouro }}</td>
          <td>{{ item.bairro }}</td>
          <td>{{ item.localidade }}</td>
          <td>
            <EditActionsTableApp :item="item" @editClick="editItem(item)"  @deleteClick="deleteItem(item)" deleteAccess="super|Locais de Rota-Deletar"  editAccess="super|Locais de Rota-Editar"/>
          </td>
        </tr>
      </tbody>
    </table>
    <PaginationApp v-model:page="pagination.page" :limit="pagination.limit" :total="pagination.total"
      @onChangePage="getItems" />

    <ModalApp v-model="formModal" :title="modalTitleComp" @confirm="saveItem">
      <div v-if="itemSelected">
        <div class="field">
          <span>Descrição</span>
          <div class="control">
            <input v-model="itemSelected.descricao" class="input" type="text" />
          </div>
        </div>
        <div class="field">
          <span>CEP</span>
        </div>
        <div class="field has-addons">
          <div class="control">
            <input v-model="itemSelected.cep" maxlength="8" class="input" type="text" />
          </div>
          <div class="control">
            <a class="button is-info" v-on:click="buscarCep(itemSelected)">
              Buscar
            </a>
          </div>
        </div>
        <div class="field">
          <span>Logradouro</span>
          <div class="control" style=" display: flex;justify-content: space-around;">
            <input v-model="itemSelected.logradouro" class="input" type="text" disabled/>
            <span class="ml-5 mr-1">N°</span>
            <input v-model="itemSelected.numero" class="input" type="text" style=" max-width: 80px;"/>
          </div>
        </div>
        <div class="field">
          <span>Complemento</span>
          <div class="control">
            <input v-model="itemSelected.complemento" class="input" type="text" />
          </div>
        </div>
        <div class="field">
          <span>Bairro</span>
          <div class="control">
            <input v-model="itemSelected.bairro" class="input" type="text" disabled/>
          </div>
        </div>
        <div class="field">
          <span>Cidade</span>
          <div class="control">
            <input v-model="itemSelected.localidade" class="input" type="text" disabled />
          </div>
        </div>
        <div class="field">
          <span>UF</span>
          <div class="control">
            <input v-model="itemSelected.uf" class="input" type="text" disabled/>
          </div>
        </div>

        <div class="field">
          <span>Latitude</span>
          <div class="control">
            <input v-model="itemSelected.lat" class="input" type="text" disabled />
          </div>
        </div>
        <div class="field">
          <span>Longitude</span>
          <div class="control">
            <input v-model="itemSelected.lng" class="input" type="text" disabled />
          </div>
        </div>
      </div>
    </ModalApp>
  </div>
</template>

<script setup>
import EditActionsTableApp from "@/components/EditActionsTableApp";
import PaginationApp from "@/components/PaginationApp";
import ModalApp from "../../../components/ModalApp.vue";
import { useConfirm } from "../../../composables/confirm";
import { ref, computed, onMounted } from "vue";
import RouteLocalService from "../services/RouteLocalService";
import ViaCepService from "../../../services/ViaCepService";
import GoogleMapService from "../../../services/GoogleMapService";



const pagination = ref({ page: 1, limit: 30, total: 0 });

const googleMapService = new GoogleMapService();

const search = ref(null);

const formModal = ref(false);


const modalTitleComp = computed(() => {
  if (!itemSelected.value) return;
  if (itemSelected.value.id) return "Editar Local";
  else return "Novo Local";
});

const confirmation = useConfirm();

const items = ref([]);

const itemSelected = ref(null);


function newItem() {
  formModal.value = true;
  itemSelected.value = {};
}

function editItem(item) {
  itemSelected.value = { ...item };
  formModal.value = true;
}
async function buscarCep() {

  if (itemSelected.value.cep === 0) return;

  const res = await ViaCepService.getByCep(itemSelected.value.cep)

  console.log(res)
  if (res.status != 200) return;
  let cepSemTraco = res.data.cep.replace(/-/g, "");
  itemSelected.value.logradouro = res.data.logradouro;
  itemSelected.value.bairro = res.data.bairro;
  itemSelected.value.complemento = res.data.complemento;
  itemSelected.value.localidade = res.data.localidade;
  itemSelected.value.uf = res.data.uf;
  itemSelected.value.cep = cepSemTraco
  const latELng = await getLatELng()
  console.log(latELng)
  itemSelected.value.lat = latELng.lat
  itemSelected.value.lng = latELng.lng


}
async function getLatELng() {
  const query = `${itemSelected.value.logradouro} ${itemSelected.value.bairro} ${itemSelected.value.complemento} ${itemSelected.value.localidade} ${itemSelected.value.uf} ${itemSelected.value.cep}`;
  console.log(query)
  const result = await googleMapService.getPlaceFromQuery(query);
  if (result.status != 200) return;
  const data =  result.data.results[0]
  return{
    lat : data.geometry.location.lat,
    lng : data.geometry.location.lng
  }

}
async function saveItem() {
  const res = await RouteLocalService.insertOrUpdate(itemSelected.value);
  if (res.status != 200) return;

  formModal.value = false;
  await getItems();
}

async function deleteItem(item) {
  const confirmResult = await confirmation.confirmation();
  if (!confirmResult) return;
  const res = await RouteLocalService.delete(item.id);
  if (res.status != 200) return;
  await getItems();
}

async function searchItems() {
  pagination.value.page = 1;
  await getItems();
}

async function getItems() {
  const res = search.value
    ? await RouteLocalService.search(
      null,
      search.value,
      pagination.value.page,
      pagination.value.limit
    )
    : await RouteLocalService.getAll(
      pagination.value.page,
      pagination.value.limit
    );
  if (res.status != 200) return;

  items.value = res.data.data.data;
  pagination.value.total = res.data.data.total;
}

onMounted(async () => {
  await getItems();
});


</script>

<style></style>
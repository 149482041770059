<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a
        @click="isOpen = !isOpen"
        role="button"
        :class="`navbar-burger ${classIsActiveIsOpen}`"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" :class="`navbar-menu ${classIsActiveIsOpen}`">
      <div class="navbar-start">
        <div v-for="(menu, index) in menus" :key="index">
          <a style="z-index: 900;"
            v-if="!menu.children || menu.children.length == 0"
            v-permission:any="menu.access"
            class="navbar-item"
            @click="navigateToRoute(menu)"
          >
            {{ menu.name }}
          </a>
          <div v-else class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" v-permission:any="menu.access"> {{ menu.name }} </a>

            <div class="navbar-dropdown is-boxed" style="z-index: 900;">
              <a style="z-index: 900;"
                v-for="(subMenu, index) in menu.children"
                :key="index"
                class="navbar-item"
                v-permission:any="subMenu.access"
                @click="navigateToRoute(subMenu)"
              >
                {{ subMenu.name }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-end" style="z-index: 900;">
        <div
          v-if="mainStore.user"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-link"> {{ mainStore.user.username }} </a>

          <div class="navbar-dropdown is-boxed" style="z-index: 900;">
            <a class="navbar-item" @click="editChangePassword">
              Alterar Senha
            </a>
          </div>
        </div>

        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-light" @click="logout">
              <strong>Sair</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <modal-app
      v-model="changePasswordForm.modalIsOpen"
      title="Novo Usuário"
      @confirm="saveChangePassword"
      @cancel="cancelChangePassword"
    >
      <div v-if="changePasswordForm.itemSelected">
        <Form class="mb-5">
          <div class="field">
            <label class="label">Senha antiga</label>
            <div class="control">
              <Field
                class="input"
                v-model="changePasswordForm.itemSelected.oldPassword"
                name="oldPassword"
                type="password"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="oldPassword"
              ></ErrorMessage>
            </div>
          </div>

          <div class="field">
            <label class="label">Nova Senha</label>
            <div class="control">
              <Field
                class="input"
                v-model="changePasswordForm.itemSelected.newPassword"
                name="newPassword"
                type="password"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="newPassword"
              ></ErrorMessage>
            </div>
          </div>

          <div class="field">
            <label class="label">Confirmação da Senha</label>
            <div class="control">
              <Field
                class="input"
                v-model="changePasswordForm.itemSelected.confirmPassword"
                name="confirmPassword"
                type="password"
                :rules="isRequired"
              ></Field>
              <ErrorMessage
                class="has-text-danger"
                name="confirmPassword"
              ></ErrorMessage>
            </div>
          </div>
        </Form>
      </div>
    </modal-app>
</template>

<script>
import UserService from "../services/UserService";
import { useMainStore } from "../stores/mainStore";
import { Form, Field, ErrorMessage } from "vee-validate";
 
export default {
  props: { menus: Array },
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      userService: new UserService(),
      mainStore: useMainStore(),
      isOpen: false,
      changePasswordForm:{
        modalIsOpen:false,
        itemSelected:null
      }
    };
  },
  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },
    navigateToRoute(menu) {
      this.$router.push(menu.route);
      this.isOpen = false;
    },
    async saveChangePassword(){
      const model = this.changePasswordForm.itemSelected;
      const userId = this.userService.getUserId();
      const res = await this.userService.updatePasswordByUserId(userId,model.oldPassword,model.newPassword,model.confirmPassword);
      if(res.status != 200) return;
      this.cancelChangePassword();
    },
    editChangePassword(){
      this.changePasswordForm.modalIsOpen = true;
      this.changePasswordForm.itemSelected = {}
    },
    cancelChangePassword(){
      this.changePasswordForm.modalIsOpen = false;
      this.changePasswordForm.itemSelected = null
    },
    logout() {
      const res = this.userService.logout();
      if (!res) return;
      this.$router.push("/login");
    },
  },
  computed: {
    classIsActiveIsOpen() {
      return this.isOpen ? "is-active" : "";
    },
  },
};
</script>

<style>
.navbar-burger {
  margin-left: 0px;
}
</style>
import client from "../api/apiClient";

class PedidoEntregaService {
  route;
  client;
  constructor() {
    this.route = "pedido-entrega";
    this.client = client;
  }

  async getAll(page, limit, filter) {
    const route = `${this.route}`;
    return await client.get(route, { params: { page, limit, ...filter } });
  }
  async getPedidosFromKeys(keyList) {
    const route = `${this.route}/route-itens`;
    return await client.post(route, keyList);
  }

  async updateStatus(item) {
    const route = `${this.route}/update-status`;
    return await client.post(route, item);
  }

  async getStatusList() {
    const route = `${this.route}/status-list`;
    return await client.get(route);
  }
  async getClientes() {
    const route = `${this.route}/cliente-list`;
    return await client.get(route);
  }
}

export default PedidoEntregaService;

<template>
  <div>
    <div id="map-canvas" :style="mapCanvasStyle"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { colors } from "../constants/mapConstants";
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();

export default {
  props: {
    entregas: { type: Array, default: () => [] },
    routeLocals: { type: Array, default: () => [] },
  },
  emits: ["onChangeKm", "onChangeMinutes"],
  data() {
    return {
      colors: colors,
      placesResult: [],
      renderers: [],
      googleMap: {
        directionsResponse: null,
        directionsService: null,
        directionRenderer: null,
        google: null,
        placesService: null,
        key: "AIzaSyDM847uX2H3Nwz1fEsb6GCBdneUAzHg2UE",
        version: "weekly",
        libraries: ["places"],
        waypointsRoute: null,
        originIndex: null,
        destinationIndex: null,
        zoom: 11,
        options: {
          zoom: 11,
          center: { lat: -23.680889, lng: -46.49868 },
        },
        map: null,
      },

      loader: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    async entregas() {
      this.noPedidosHandler();
      await this.findPlaces();
    },
  },
  computed: {
    mapCanvasStyle() {
      return `height: ${this.window.height}px; width: ${this.window.width}px`;
    },
  },
  methods: {
    noPedidosHandler() {
      if (this.entregas.length) return;

      this.$router.push({ name: "PedidosEntregas" });
      const message = "Nenhum pedido selecionado.";
      notify({
        position: "top center",
        title: "",
        text: message,
        type: "info",
        duration: 2000,
      });
    },
    removeMarkerByEntrega(entrega) {
      const placeResult = this.placesResult.find(
        (placeResult) => placeResult.entrega == entrega
      );
      this.removeMarker(placeResult.marker);
    },
    removeMarker(marker) {
      marker.setMap(null);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    createMarker(lat, lng, icon, index) {
      const marker = new this.googleMap.google.maps.Marker({
        position: { lat, lng },
        // icon: { url: icon },
        map: this.googleMap.map,
      });
      marker.index = index;
      //   marker.addListener("click", () => {
      //     this.markerClick(marker);
      //   });

      // this.googleMap.options.center = { lat, lng };

      return marker;
    },
    setOrigin(index) {
      console.log(this.placesResult[index])
      this.placesResult.forEach((i) => (i.origin = false));
      this.placesResult[index].origin = true;
    },
    setDestination(index) {
      this.placesResult.forEach((i) => (i.destination = false));
      this.placesResult[index].destination = true;
    },
    markerPlaces() {
      this.placesResult.forEach((placeResult) => {
        this.createMarker(
          placeResult.lat,
          placeResult.lng,
          null,
          placeResult.index
        );
      });
    },

    async findPlaces() {
      if (this.entregas == null || this.entregas.length == 0) {
        return;
      }
      this.placesResult = [];
      await this.entregas.forEach(async (entrega) => {
        await this.findPlace(entrega);
      });

      const firstPlaceResult = this.placesResult[0];

      if (!firstPlaceResult) return;

      const newOptions = {
        zoom: this.googleMap.zoom,
        center: { lat: firstPlaceResult.lat, lng: firstPlaceResult.lng },
      };
      this.googleMap.map.setOptions(newOptions);
    },

    getEnderecoEntrega(entrega) {
      return `${entrega.endereco} ${entrega.cidade}`;
    },

    async findPlace(entrega) {
      const self = this;
      const endereco = this.getEnderecoEntrega(entrega);

      var request = {
        query: endereco,
        fields: ["name", "geometry"],
      };
      const placesService = this.googleMap.placesService;
      if (placesService === null) return;
      await placesService.findPlaceFromQuery(
        request,
        async function (res, status) {
          if (status != "OK") return;

          const result = res[0];

          const item = {
            index: self.entregas.indexOf(entrega),
            entrega: entrega,
            name: result.name,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          };

          const marker = self.createMarker(
            item.lat,
            item.lng,
            null,
            item.index
          );

          item.marker = marker;

          self.placesResult.push(item);
        }
      );
    },
    orderDirections() {
      if (this.googleMap.directionsResponse === null) return;
      const route = this.googleMap.directionsResponse.routes[0];
      const wayPointOrder = route.waypoint_order;
      wayPointOrder.forEach((waypoint, index) => {
        this.googleMap.waypointsRoute[waypoint].seq = index;
      });
      this.googleMap.waypointsRoute.forEach(placeRoute => {
        const placeRouteExist = this.placesResult.find(pl => pl.lat === placeRoute.lat && pl.lng === placeRoute.lng && !pl.orderned);
        placeRouteExist.seq = placeRoute.seq + 2;
        placeRouteExist.orderned = true
    
      });
      const origin = this.placesResult.find(placeResult => placeResult.origin === true);
      origin.seq = 1
      if (origin.destination === false)
        this.placesResult.find(placeResult => placeResult.destination === true).seq = this.placesResult.length
      //this.placesResult.filter(placeResult => placeResult.seq)
      const order = this.placesResult.map((item, index) => {
        return {
          index,
          seq: item.seq
        };
      });
      this.$emit('orderOnChange', order)
      // this.placesResult = this.placesResult.sort(function(a, b){return a.seq-b.seq})


    },
    async createRouteRequest() {
      const routes = this.placesResult;

      const originRoute = routes.find((item) => item.origin === true);
      const destinationRoute = routes.find((item) => item.destination === true);

      const originIndex = routes.indexOf(originRoute);
      const destinationIndex = routes.indexOf(destinationRoute);

      const waypointsRoute = routes.filter(
        (item, index) => index != originIndex && index != destinationIndex
      );

      const waypointsRouteMap = waypointsRoute.map((item) => {
        return { location: { lat: item.lat, lng: item.lng }, stopover: true };
      });

      this.googleMap.waypointsRoute = waypointsRoute;
      this.googleMap.originIndex = originIndex;
      this.googleMap.destinationIndex = destinationIndex;
      const google = await this.loader.load();
      const routeRequest = {
        origin: { lat: originRoute.lat, lng: originRoute.lng },
        destination: { lat: destinationRoute.lat, lng: destinationRoute.lng },
        waypoints: waypointsRouteMap,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      return routeRequest;
    },
    async setRouteInMap() {
      const self = this;
      const routeRequest = await this.createRouteRequest();
      self.googleMap.directionsService
        .route(routeRequest)
        .then((response) => {
          let renderer = self.googleMap.directionRenderer;
          renderer.response = response;
          renderer.setDirections(response);
          renderer.setMap(self.googleMap.map);
          self.renderDirectionsPolylines(response, self.googleMap.map);
          // renderer.setOptions({
          //   suppressMarkers: true,
          //   preserveViewport: true,

          //   polylineOptions: {
          //     strokeColor: "#50C7C7",
          //   },
          // });
          self.googleMap.directionsResponse = response;
          self.getKmTotal();
          self.getDurationTotal();
          self.orderDirections();
        })
        .catch((err) => {
          console.log(err);
          window.alert("Directions request failed due to " + status);
        });
    },

    sendKm(km) {
      this.$emit("onChangeKm", km);
    },
    sendMinutes(min) {
      this.$emit("onChangeMinutes", min);
    },

    getKmTotal() {
      const directionsResponse = this.googleMap.directionsResponse;

      if (!directionsResponse || directionsResponse.routes.length == 0)
        return 0;

      const route = directionsResponse.routes[0];
      let kms = 0;
      route.legs.forEach((leg) => {
        kms += leg.distance.value;
      });
      const result = `${Math.ceil(kms / 1000)} km`;
      this.sendKm(result);
      return result;
    },
    getDurationTotal() {
      const directionsResponse = this.googleMap.directionsResponse;
      let result = null;
      if (!directionsResponse || directionsResponse.routes.length == 0) {
        result = `${0} minuto(s)`;
        this.sendMinutes(result);
        return;
      }
      const route = directionsResponse.routes[0];
      let duration = 0;
      route.legs.forEach((leg) => {
        duration += leg.duration.value;
      });
      let minutes = Math.ceil(duration / 60);
      if (minutes > 60) {
        result = `${Math.ceil(minutes / 60)} horas`;
        this.sendMinutes(result);
        return;
      }
      result = `${minutes} minutos`;
      this.sendMinutes(result);
      return result;
    },

    async inicializePlacesService() {
      const google = await this.loader.load();

      this.googleMap.placesService = new google.maps.places.PlacesService(
        this.googleMap.map
      );
    },
    async inicializeDirectionsService() {
      const google = await this.loader.load();

      this.googleMap.directionsService = new google.maps.DirectionsService(
        this.googleMap.map
      );
    },
    async inicializeGoogleServices() {
      const google = await this.loader.load();
      const map = this.googleMap.map;
      this.googleMap.directionsService = new google.maps.DirectionsService(map);
      this.googleMap.directionRenderer = new google.maps.DirectionsRenderer();
      this.googleMap.placesService = new google.maps.places.PlacesService(map);
    },
    async inicializeDirectionsRenderer() {
      const google = await this.loader.load();

      this.googleMap.directionRenderer = new google.maps.DirectionsRenderer(
        this.googleMap.map
      );
    },
    async inicializeGoogle() {
      const google = await this.loader.load();
      this.googleMap.google = google;
    },
    async inicializeMap() {
      const { Map } = await this.loader.importLibrary("maps");

      this.googleMap.map = new Map(
        document.getElementById("map-canvas"),
        this.googleMap.options
      );
    },
    async renderDirectionsPolylines(response, map) {
      const google = await this.loader.load();
      var polylineOptions = {
        strokeColor: "#C83939",
        strokeOpacity: 1,
        strokeWeight: 4,
        suppressMarkers: true,
        preserveViewport: true,
      };

      let polylines = [];

      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < polylines.length; i++) {
        polylines[i].setMap(null);
      }
      var legs = response.routes[0].legs;
      for (i = 0; i < legs.length; i++) {
        var steps = legs[i].steps;
        for (var j = 0; j < steps.length; j++) {
          var nextSegment = steps[j].path;
          var stepPolyline = new google.maps.Polyline(polylineOptions);
          stepPolyline.setOptions({
            strokeColor: this.colors[i],
          });
          for (var k = 0; k < nextSegment.length; k++) {
            stepPolyline.getPath().push(nextSegment[k]);
            bounds.extend(nextSegment[k]);
          }
          polylines.push(stepPolyline);
          stepPolyline.setMap(map);
        }
      }
      map.fitBounds(bounds);
    },

    async reloadMap() {
      await this.inicializeGoogle();
      await this.inicializeMap();
      await this.inicializeGoogleServices();
      await this.findPlaces();
    },
  },
  async mounted() {
    this.loader = new Loader({
      apiKey: this.googleMap.key,
      version: this.googleMap.version,
      libraries: this.googleMap.libraries,
    });

    await this.inicializeGoogle();

    await this.inicializeMap();

    //await this.inicializeDirectionsService();

    // await this.inicializeDirectionsRenderer();

    //await this.inicializePlacesService();
    await this.inicializeGoogleServices();

    await this.findPlaces();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style></style>
import { defineStore } from "pinia";

export const useMainStore = defineStore("main", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: false,
      user: null,
      notifications: [],
      isConfirm: false,
      confirmResolve: null,
    };
  },
  actions: {
    confirmation() {
      this.isConfirm = true;
      return new Promise((res) => {
        this.confirmResolve = res;
      });
    },
    okConfirmation() {
      this.confirmResolve(true);
      this.isConfirm = false;
    },
    cancelConfirmation() {
      this.confirmResolve(false);
      this.isConfirm = false;
    },

    addNotification(notifications) {
      if (!notifications) return;
      if (Array.isArray(notifications)) this.notifications = [...notifications];
      else this.notifications.push(notifications);
    },
    clearNotification(notification) {
      var index = this.notifications.indexOf(notification);
      if (index < 0) return;
      this.notifications.splice(index, 1);
    },
    clearNotofications() {
      this.notifications = [];
      ("");
    },
    setUser(user) {
      this.user = user;
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
  },
  persist: {
    enabled: true,
  },
});

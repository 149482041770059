import { ref } from "vue";

export function useForm(formRef) {
  const form = ref(formRef);


  async function resetValdiation() {
    if (!form.value) return;
    return form.value.resetForm();
  }

  async function validate() {
    if (!form.value) return;
    const validation = await form.value.validate();
    return validation.valid;
  }

  function onObjectValuePropertyChanged(
    value,
    property,
    modelValue,
    emitUpdateFunction
  ) {
    const obj = { ...modelValue };
    obj[property] = value;
    if (emitUpdateFunction) emitUpdateFunction(obj);
  }

  function onObjectEventPropertyChanged(
    event,
    property,
    modelValue,
    emitUpdateFunction
  ) {
    const value = event.target.value;
    const obj = { ...modelValue };
    obj[property] = value;
    emitUpdateFunction(obj);
  }

  function onValuePropertyChanged(value, emitUpdateFunction) {
    if (emitUpdateFunction) emitUpdateFunction(value);
  }

  function onValueEventPropertyChanged(event, emitUpdateFunction) {
    const value = event.target.value;
    emitUpdateFunction(value);
  }

  return {
    onObjectValuePropertyChanged,
    onObjectEventPropertyChanged,
    onValuePropertyChanged,
    onValueEventPropertyChanged,
    validate,
    resetValdiation
  };
}

<template>
  <div :class="`modal ${isActiveClassComp}`">
    <div class="modal-background"></div>
    <div class="modal-card" :style="modalCardStyle">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot is-justify-content-right">
        <slot name="buttons">
          <button class="button" @click="cancelHandle">Cancelar</button>
          <button class="button is-success" @click="confirmHandle">
            Confirmar
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import { useConfirm } from "../composables/confirm";
export default {
  props: { modelValue: Boolean, title: String, confirmOk: Boolean, width:Number},
  computed: {
    modalCardStyle(){
      return `width:${this.width?this.width:640}px`
    },
    isActiveClassComp() {
      return this.modelValue ? "is-active" : "";
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:modelValue", false);
      this.$emit("close", false);
      this.$emit("cancel");
    },
    async confirmHandle() {
      if (this.confirmOk) {
        const { confirmation } = useConfirm();
        const res = await confirmation();
        if (res) {
          this.$emit("confirm");
          return;
        } else {
          return;
        }
      }
      this.$emit("confirm");
    },
    cancelHandle() {
      this.$emit("update:modelValue", false);
      this.$emit("cancel");
    },
  },
};
</script>

<style>
.modal-card{

}
</style>
<template>
  <div v-if="modelValue">
    <Form ref="form" class="mb-5">
      <div class="field">
        <label class="label">Nova Senha</label>
        <div class="control">
          <Field
            class="input"
            :value="modelValue.newPassword"
            name="password"
            type="password"
            :rules="isRequired"
            @input="
              onObjectEventPropertyChanged(
                $event,
                'newPassword',
                modelValue,
                modelValueInput
              )
            "
          ></Field>
          <ErrorMessage class="has-text-danger" name="password"></ErrorMessage>
        </div>
      </div>

      <div class="field">
        <label class="label">Confirmação da Senha</label>
        <div class="control">
          <Field
            class="input"
            :value="modelValue.confirmPassword"
            name="confirmPassword"
            type="password"
            :rules="isRequired"
            @input="
              onObjectEventPropertyChanged(
                $event,
                'confirmPassword',
                modelValue,
                modelValueInput
              )
            "
          ></Field>
          <ErrorMessage
            class="has-text-danger"
            name="confirmPassword"
          ></ErrorMessage>
        </div>
      </div>
    </Form>
  </div>
</template>
  
  <script setup>
import { useForm } from "../composables/form";
import { defineProps, defineEmits, defineExpose, ref } from "vue";

defineProps({ modelValue: Object });
const emit = defineEmits(["update:modelValue"]);

const form = ref();

const { onObjectEventPropertyChanged, validate, resetValidation } =
  useForm(form);

defineExpose({ validate, resetValidation });

function modelValueInput(value) {
  emit("update:modelValue", value);
}

function isRequired(value) {
  if (value && value.trim()) {
    return true;
  }
  return "Campo Obrigatório";
}
</script>
  
  <style>
</style>
<template>
  <div>
    <div class="field">
      <label class="label">{{ label }}</label>
      <div :class="`control ${hasIconLeftClass} ${hasIconRightClass}`">
        <Field
          v-bind="$attrs"
          :class="`input`"
          @input="$emit('update:modelValue', $event.target.value)"
          :value="modelValue"
          :rules="rules"
          :name="$attrs.name ? $attrs.name : label"
        ></Field>
        <span v-if="preIcon" class="icon is-small is-left">
          <i :class="preIcon"></i>
        </span>
        <span v-if="posIcon" class="icon is-small is-right">
          <i :class="posIcon"></i>
        </span>
      </div>
      <ErrorMessage class="has-text-danger" :name="$attrs.name ? $attrs.name : label"></ErrorMessage>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  components: { Field, ErrorMessage },
  props: {
    label: String,
    modelValue: String,
    preIcon: String,
    posIcon: String,
    rules: [Object , String , Function],
  },
  data() {
    return {};
  },
  computed: {
    hasIconLeftClass() {
      return this.preIcon ? "has-icons-left" : "";
    },
    hasIconRightClass() {
      return this.posIcon ? "has-icons-right" : "";
    },
    // isDangerClass() {
    //   return this.isError ? "is-danger" : "";
    // },
    // isError() {
    //   return this.errorMessage != null;
    // },
  },
};
</script>

<style>
</style>
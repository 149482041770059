export default [
  {
    name: "Produção",
    access: "super|Produção-Visualizar",
    children: [
      {
        name: "Relatório de Ordem Produção",
        access: "super|Relatório de Ordem Produção-Visualizar",
        route: "/ordem-producao/relatorio/ordem-producao",
      },
    ],
  },
  {
    name: "Comercial",
    access: "super|Comercial-Visualizar",
    children: [
      {
        name: "Relatório de Vendas",
        access: "super|Relatório de Vendas-Visualizar",
        route: "/comercial/relatorio/vendas",
      },
    ],
  },
  {
    name: "Pedidos",
    access: "super|Pedidos-Visualizar",
    children: [
      {
        name: "Entregas",
        access: "super|Pedidos Entregas-Visualizar",
        route: "/pedidos/entregas",
      },
      {
        name: "Roteirizados",
        access: "super|Rotas de Entrega-Visualizar",
        route: "/pedidos/entregas/route/all",
      },
      {
        name: "Locais de Rota",
        access: "super|Locais de Rota-Visualizar",
        route: "/route/locals",
      },
    ],
  },
  {
    name: "Configurações",
    access: "super|Configurações-Visualizar",
    children: [
      { name: "Usuários", route: "/user"  , access: "super|Usuários-Visualizar",},
      { name: "Grupos de Acesso", route: "/access-group" , access: "super|Grupos de Acesso-Visualizar" },
    ],
  },
];

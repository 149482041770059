<template>
  <NavBarApp :menus="menus"></NavBarApp>
  <div class="page">
    <router-view />
  </div>
</template>

<script setup>
import UserService from "../services/UserService"
import menusApp from "../constants/menus"
import { ref,getCurrentInstance   } from "vue";
import NavBarApp from "../components/NavBarApp.vue";
const menus = ref(menusApp);

const app = getCurrentInstance();

const userService = new UserService();
userService.setUserPermissions(app);

</script>



<style>
.page {
  padding: 15px;
}
</style>

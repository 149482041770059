<template>
  <div>
    <ListValueApp
      :modelValue="modelValue"
      title="Tipo de Acesso"
      item-text="Tipo"
      @new-item="listValue.newItem"
      @edit-item="listValue.editItem"
      @delete-item="listValue.deleteItem"
    ></ListValueApp>
    <modal-app
      v-model="listValue.modalIsOpen"
      @confirm="this.listValue.saveItem"
      @cancel="listValue.cancelItem"
    >
      <Form class="mb-5">
        <div class="field">
          <label class="label">Nome</label>
          <div class="control">
            <Field
              class="input"
              v-model="listValue.itemSelected"
              name="name"
              :rules="isRequired"
            ></Field>
            <ErrorMessage class="has-text-danger" name="name"></ErrorMessage>
          </div>
        </div>
      </Form>
    </modal-app>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useListValue } from "../composables/listValue";
import ListValueApp from "../components/ListValueApp.vue";
export default {
  props: { modelValue: Array },
  components: { ListValueApp, Form, Field, ErrorMessage },
  data() {
    return {
      listValue: useListValue(this.modelValue, null, this.sendUpdateEvent),
    };
  },
  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Campo Obrigatório";
    },
    sendUpdateEvent(items) {
      this.$emit("update:modelValue", items);
    },
  },
};
</script>

<style>
</style>
import axios from "axios";
import { useMainStore } from "../stores/mainStore";
import { useNotification } from "@kyvg/vue3-notification";

const client = axios.create({
  baseURL: "https://viacep.com.br/ws/",

});

client.interceptors.request.use((req) => {
  const mainStore = useMainStore();
  mainStore.setLoading(true);
  return req;
});

client.interceptors.response.use(
  (res) => {
    const mainStore = useMainStore();
    mainStore.setLoading(false);

    let message = "Sucesso";

    const { notify } = useNotification();

    if (res.config.method != "get") {
      notify({
        position: "top center",
        title: "",
        text: message,
        type: "success",
        duration: 2000,
      });
    }

    return res;
  },
  (error) => {

    const mainStore = useMainStore();
    mainStore.setLoading(false);


    let errorsString = "Cep não encontrado"

  
    const { notify } = useNotification();

    notify({
      position: "top center",
      title: "Error",
      text: errorsString,
      type: "error",
      duration: 60000,
    });

    return error;
  }
);

export default client;

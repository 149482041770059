<template>
  <div class="pagination">
    <button
      :disabled="disabledLastPageComp"
      class="button is-info is-light"
      @click="changePage(false, true, false)"
    >
      <span class="icon">
        <i class="fa-solid fa-angles-left"></i>
      </span>
    </button>
    <button
      class="button is-info is-light"
      @click="changePage(false, false, false)"
      :disabled="disabledLastPageComp"
    >
      Anterior
    </button>
    <button
      :disabled="disabledNextPageComp"
      class="button is-info is-light"
      @click="changePage(false, false, true)"
    >
      Próxima
    </button>
    <button
      :disabled="disabledNextPageComp"
      class="button is-info is-light"
      @click="changePage(true, false, true)"
    >
      <span class="icon">
        <i class="fa-solid fa-angles-right"></i>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      required: true,
    },
    limit: {
      required: true,
    },
    total: {
      required: true,
    },
  },

  data: () => {
    return {};
  },

  computed: {
    disabledLastPageComp() {
      return this.page === 1;
    },
    disabledNextPageComp() {
      return this.page == this.lastPage(this.limit, this.total);
    },
  },

  methods: {
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    async changePage(last, first, next = true) {
      if (last === true) {
        const page = this.lastPage(this.limit, this.total);
        this.$emit("update:page", page);
        return;
      }
      if (first === true) {
        const page = 1;
        this.$emit("update:page", page);
        return;
      }
      if (!next && this.page == 1) return;
      const page = next === true ? this.page + 1 : this.page - 1;
      this.$emit("update:page", page);

      this.$emit("onChangePage", page);
    },
  },

  mounted() {},
};
</script>

<style>
</style>
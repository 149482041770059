<template>
  <div class="page-container">
    <div class="panel" style="z-index: 10; background-color: white">
      <div v-if="rotaCalculada === true">
        <div class="field">
          <label class="label">Data *</label>
          <div class="control">
            <input v-model="pedidoEntregaRoute.date" class="input" type="date" placeholder="Data" />
          </div>
        </div>
        <div class="field">
          <label class="label">Descrição</label>
          <div class="control">
            <input v-model="pedidoEntregaRoute.description" class="input" type="input" placeholder="Descrição" />
          </div>
        </div>
        <div class="field">
          <label class="label">Observação </label>
          <div class="control">
            <input v-model="pedidoEntregaRoute.obs" class="textarea" type="textarea" placeholder="Observação" />
          </div>
        </div>
      </div>
      <div v-permission:any="'super|Rotas de Entrega-Editar'" >
      <div style=" 
          display: flex;
          gap: 10px;
          justify-content: space-between;
          margin-top: 30px;
        ">
       
        <button class="button is-info" @click="routeButtonClick">
          Calcular Rota
        </button>
        <button class="button is-primary" @click="controlarModal">
          Escolher local de rota
        </button>
        <button v-if="this.pedidoEntregaRoute.date !== null &&
          this.pedidoEntregaRoute.date !== ''
          " class="button is-success" @click="salvarRota">
          Salvar Rota
        </button>
      </div>
      <div style="margin-top: 15px; margin-left: 15px">
        <p>
          <b>{{ kmTotal }}</b>
        </p>
        <p>
          <b>{{ minutesTotal }}</b>
        </p>
      </div>
      <ul>

        <li v-for="item in items" :key="item.id">
          <div class="pedido-item">
            <div>
              <p class="has-text-primary" v-if="item.origin === true" style="font-size: 20px">
                <b>ORIGEM</b>
              </p>
              <p class="has-text-info" v-if="item.destination === true" style="font-size: 20px">
                <b>DESTINO</b>
              </p>
              <p>
                <span class="mr-3 has-text-info	" v-if="item.routeLocalId">
                  <i class="fa-solid fa-location-dot"></i>
                </span>
                <span class="mr-3 has-text-warning-dark" v-else>
                  <i class="fa-solid fa-truck-ramp-box"></i>
                </span>
                <span v-if="item.seq">
                  <b> {{ item.seq }} -
                  </b>
                </span>
                <b>{{ item.numOrdem }}</b>

              </p>
              <p>
                <b>{{ item.endereco }} - {{ item.cidade }} </b>
              </p>
              <p>
                <b>{{ item.produto }}</b>
              </p>
            </div>
            <div class="btn-actions" style="gap: 3px">
              <button data-tooltip="Marcar como Origem" :disabled="item.origin === true" @click="setOrigem(item)"
                class="button is-primary has-tooltip-left">
                <span class="icon is-small">
                  <i class="fa-solid fa-location-dot"></i>
                </span>
              </button>

              <button data-tooltip="Marcar como Destino" :disabled="item.destination === true" @click="setDestino(item)"
                class="button is-info has-tooltip-left">
                <span class="icon is-small">
                  <i class="fa-solid fa-flag-checkered"></i>
                </span>
              </button>

              <button v-if="item.routeLocal" @click="removeRouteLocal(item.routeLocalId)"
                class="button is-danger mb-3 has-tooltip-left" data-tooltip="Remover Local de rota">
                <span class="icon is-small">
                  <i class="fa-solid fa-trash"></i>
                </span>
              </button>

              <button v-else @click="removeItem(item)" class="button is-danger mb-3 has-tooltip-left"
                data-tooltip="Remover Pedido">
                <span class="icon is-small">
                  <i class="fa-solid fa-trash"></i>
                </span>
              </button>
            </div>
          </div>
          <hr class="solid" />
        </li>
      </ul>
    </div>
    </div>
    <div class="map-content">
      <google-map-route :entregas="items" ref="googleMapRouteRef" @on-change-km="onChangeKmHandle"
        @on-change-minutes="onChangeMinutesHandle" @orderOnChange="orderOnChange"></google-map-route>
    </div>
  </div>
  <ModalApp style="z-index: 999;" title="Escolher local" v-model="modalVisivel" :width="750" @confirm="controlarModal">
    <div style="z-index: 999;">
      <div class="is-size-3 has-text-weight-bold mb-5">Locais de Rota</div>

      <div class="field mb-5">
        <label class="label">Pesquisar</label>
        <div class="control">
          <input v-model="search" class="input" type="text" placeholder="Pesquise aqui..." @keyup.enter="searchItems" />
        </div>
      </div>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Logradouro</th>
            <th>Bairro</th>
            <th>Cidade</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in local" :key="index">
            <td>{{ item.descricao }}</td>
            <td>{{ item.logradouro }}</td>
            <td>{{ item.bairro }}</td>
            <td>{{ item.localidade }}</td>
            <td>
              <button data-tooltip="Selecionar" @click="addRouteLocal(item)" class="button is-primary has-tooltip-left">
                <span class="icon is-small">
                  <i class="fa-solid fa-check"></i>
                </span>
              </button>

            </td>

          </tr>
        </tbody>
      </table>
      <PaginationApp v-model:page="pagination.page" :limit="pagination.limit" :total="pagination.total"
        @onChangePage="getLocals" />
    </div>
  </ModalApp>
</template>

<script>
import PedidoEntregaService from "../services/PedidoEntregaService";
import PedidoEntregaRouteService from "../services/PedidoEntregaRouteService";
import { useRouteNewStore } from "../stores/routeNewStore";
import { useConfirmStore } from "../stores/confirmStore";
import GoogleMapRoute from "../components/GoogleMapRoute.vue";
import PaginationApp from "@/components/PaginationApp";
import ModalApp from "../components/ModalApp.vue";
import RouteLocalService from "../tenanties/STGraf/services/RouteLocalService";

export default {
  components: { GoogleMapRoute, ModalApp, PaginationApp },
  data() {
    return {
      routeNewStore: useRouteNewStore(),
      consfirmeStore: useConfirmStore(),
      items: [],
      local: [],
      routeLocals: [],
      search: null,
      pedidoEntregaRoute: {
        date: "",
        description: "",
        obs: "",
        routeItems: [],
      },
      pagination: {
        page: 1,
        limit: 30,
        total: 0
      },
      pedidoEntregaService: new PedidoEntregaService(),
      pedidoEntregaRouteService: new PedidoEntregaRouteService(),
      routeLocalService: RouteLocalService,
      modalVisivel: false,
      kmTotal: null,
      minutesTotal: null,
      rotaCalculada: false,
    };
  },
  computed: {},
  methods: {
    orderOnChange(orders) {
      orders.forEach(order => {
        this.items[order.index].seq = order.seq
      });
      this.items = this.items.sort((a, b) => { return a.seq - b.seq })
    },
    addRouteLocal(item) {
      if (this.routeLocals.find(routeLocal => routeLocal.id == item.id) != null) {
        this.controlarModal()
      }
      else {
        this.routeLocals.push(item);
        this.mapearRouteLocalsToItems();
        this.controlarModal()
      }
    },
    controlarModal() {
      this.modalVisivel = !this.modalVisivel;
    },
    async getLocals() {
      const res = this.search
        ? await this.routeLocalService.search(
          null,
          this.search,
          this.pagination.page,
          this.pagination.limit
        )
        : await this.routeLocalService.getAll(
          this.pagination.page,
          this.pagination.limit
        );
      if (res.status != 200) return;

      this.local = res.data.data.data;
      this.pagination.total = res.data.data.total;
    },
    mapearRouteLocalsToItems() {
      if (this.routeLocals.length == 0) return;
      const routeLocalsToEntregas = this.routeLocals.map(routeLocal => {
        return {
          routeLocalId: routeLocal.id,
          idEntrega: null,
          idItemEntrega: null,
          tipo: "routeLocal",
          numOrdem: "Local de rota",
          dataEntrega: "2023-08-17T00:00:00",
          produto: "Local de rota",
          peso: null,
          cliente: "Local de rota",
          endereco: `${routeLocal.logradouro} ${routeLocal.bairro} ${routeLocal.complemento} ${routeLocal.localidade} ${routeLocal.uf} ${routeLocal.cep}`,
          cidade: routeLocal.localidade,
          restricoes: null,
          status: "",
          routeLocal: true
        };
      })
      routeLocalsToEntregas.forEach(routeLocal => {
        if (!this.items.find(item => item.routeLocalId == routeLocal.routeLocalId))
          this.items.push(routeLocal)

      });
      this.items = [...this.items]
    },
    mapearPlacesResultsToRouteItem(placesResult) {
      this.pedidoEntregaRoute.routeItems = placesResult.map((item) => {
        return {
          seq: item.seq,
          idEntrega: item.entrega.idEntrega,
          idItemEntrega: item.entrega.idItemEntrega,
          routeLocalId: item.entrega.routeLocalId,
          lng: item.lng,
          lat: item.lat,
          origin: item.origin,
          destination: item.destination,
          description: item.entrega.endereco + "" + item.entrega.cidade,
        };
      });

    },
    getHasPermissionEditar() {
      return this.$gates.hasAnyPermission("super|Rotas de Entrega-Editar");
    },
    async salvarRota() {
      this.mapearPlacesResultsToRouteItem(
        this.$refs.googleMapRouteRef.placesResult
      );

      try {
        const resposta = await this.pedidoEntregaRouteService.insert(
          this.pedidoEntregaRoute
        );
        console.log(resposta);
        if (resposta.status !== 200) {
          console.error("Erro ao cadastrar pedidos");
          return;
        }
      } catch (erro) {
        console.error(erro);
      }
      this.$router.push({ name: "RouteAll" });

      return;
    },
    onChangeKmHandle(km) {
      this.kmTotal = km;
    },
    onChangeMinutesHandle(min) {
      this.minutesTotal = min;
    },
    async routeButtonClick() {
      const originExists =
        this.items.find((item) => item.origin == true) != null &&
        this.items.find((item) => item.destination == true) != null;
      if (!originExists) {
        await this.consfirmeStore.alert(
          "Necessário definir um local de origem e um local de destino"
        );
        return;
      }
      this.rotaCalculada = true;
      this.$refs.googleMapRouteRef.setRouteInMap();
    },
    ordenarLista() {
      this.items.sort((a, b) => {
        if (a.origin && !b.origin) return -1;
        if (!a.origin && b.origin) return 1;
        if (a.destination && !b.destination) return 1;
        if (!a.destination && b.destination) return -1;
        return 0;
      });
    },
    async setOrigem(item) {
      if (item.origin === true) {
        const result = await this.consfirmeStore.confirmation(
          "Remover local de origem?"
        );
        if (!result) return;
        this.items.forEach((i) => (i.origin = false));
      } else {
        const result = await this.consfirmeStore.confirmation(
          "Definir como local de origem?"
        );
        if (!result) return;
        this.items.forEach((i) => (i.origin = false));
        item.origin = true;
      }
      const index = this.items.indexOf(item);
      this.$refs.googleMapRouteRef.setOrigin(index);
    },
    async setDestino(item) {
      if (item.destination === true) {
        const result = await this.consfirmeStore.confirmation(
          "Remover local de destino?"
        );
        if (!result) return;
        this.items.forEach((i) => (i.destination = false));
      } else {
        const result = await this.consfirmeStore.confirmation(
          "Definir como local de destino?"
        );
        if (!result) return;
        this.items.forEach((i) => (i.destination = false));
        item.destination = true;
      }
      const index = this.items.indexOf(item);
      this.$refs.googleMapRouteRef.setDestination(index);
    },
    async getPedidosFromKeys() {
      try {
        const resposta = await this.pedidoEntregaService.getPedidosFromKeys(
          this.routeNewStore.selected
        );

        if (resposta.status !== 200) {
          console.error("Erro ao obter pedidos");
          return;
        }

        this.items = resposta.data.data;
        this.mapearRouteLocalsToItems();
      } catch (erro) {
        console.error(erro);
      }
    },
    async removeItem(item) {
      const result = await this.consfirmeStore.confirmation("Tem certeza?");
      if (!result) return;

      if (this.items.length > 0) {
        this.$refs.googleMapRouteRef.removeMarkerByEntrega(item);
        this.routeNewStore.removeSelected(item);
        await this.getPedidosFromKeys();
        this.$refs.googleMapRouteRef.inicializeMap();
        this.rotaCalculada = false;
      } else {
        return;
      }
    },
    async removeRouteLocal(routeLocalId) {
      const result = await this.consfirmeStore.confirmation("Tem certeza?");
      if (!result) return;
      console.log(routeLocalId);
      const item = this.routeLocals.find(routeLocal => routeLocal.id == routeLocalId)
      console.log(item);
      const routeLocalIndex = this.routeLocals.indexOf(item);
      console.log(routeLocalIndex);
      this.routeLocals.splice(routeLocalIndex, 1);
      const routeLocalItem = this.items.find(item => item.routeLocalId == routeLocalId)
      const routeLocalItemIndex = this.items.indexOf(routeLocalItem)
      this.items.splice(routeLocalItemIndex, 1)
      this.$refs.googleMapRouteRef.removeMarkerByEntrega(routeLocalItem);
      await this.getPedidosFromKeys();
      this.$refs.googleMapRouteRef.inicializeMap();
      this.rotaCalculada = false;
      return;
    }

  },
  mounted() {
    this.getPedidosFromKeys();
    this.getLocals();
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  height: 100vh;
}

.panel {
  width: 30%;
  height: 100vh;
  padding: 10px;
  background-color: white;
  overflow: auto;
}

.map-content {
  width: 70%;
}

hr.solid {
  border: 0px;
  padding: 0px;
  border: 1px solid lightgray;
  margin: 0px;
}

.pedido-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 20px;
}

.btn-actions {
  display: grid;
  flex-direction: column;
}
</style>

import client from "../api/apiClient";

class ServiceBase {
  client;
  path;

  constructor(path) {
    this.client = client;
    this.path = path;
  }

  getAll(page, limit, sortBy, sortDesc) {
    return this.client.get(this.path, {
      params: { page, limit, sortBy, sortDesc },
    });
  }

  search(fieldsArray, search, page, limit, sortBy, sortDesc) {
    let fields = null;
    if (fieldsArray && fieldsArray.length > 0) {
      fields = fieldsArray.toString();
    }
    return this.client.get(`${this.path}/search`, {
      params: { fields, search, page, limit, sortBy, sortDesc },
    });
  }

  getById(id) {
    return this.client.get(`${this.path}/${id}`);
  }

  insertOrUpdate(obj) {
    return obj.id ? this.update(obj) : this.insert(obj);
  }

  insert(obj) {
    return this.client.post(this.path, obj);
  }

  update(obj) {
    return this.client.put(this.path, obj);
  }

  delete(id) {
    return this.client.delete(`${this.path}/${id}`);
  }
}

export default ServiceBase;

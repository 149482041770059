import { ref, reactive } from "vue";
import {useConfirm} from "../composables/confirm"

export function useListCrud(service, defaultItemValue, searchFields) {

  const {confirmation} = useConfirm();
  const pagination = reactive({ page: 1, limit: 30, total: 0 });
  const items = ref([]);
  const itemSelected = ref();
  const indexSelected = ref();
  const modalIsOpen = ref(false);
  const search = ref();


  async function saveItem() {

    const con = await confirmation();
    if(!con) return;

    const res = await service.insertOrUpdate(itemSelected.value);
    if (res.status != 200) return;

    cancelItem();
    await getItems();
  }

  async function getItemsSearch(value) {
    search.value = value;
    pagination.page = 1;
    await getItems();
  }

  async function getItems() {
    const res = search.value
      ? await service.search(
          searchFields,
          search.value,
          pagination.page,
          pagination.limit
        )
      : await service.getAll(pagination.page, pagination.limit);

    if (res.status != 200) return;

    items.value = res.data.data.data;
    pagination.total = res.data.data.total;
  }

  function newItem() {
    clearItem();
    if (defaultItemValue) itemSelected.value = defaultItemValue();
    else itemSelected.value = "";
    modalIsOpen.value = true;
  }

  function editItem(item) {
    if (typeof item == "string") {
      itemSelected.value = item.toString();
    } else {
      let itemTemp = {};
      Object.assign(itemTemp, item);
      itemSelected.value = itemTemp;
    }
    modalIsOpen.value = true;
  }

  function clearItem() {
    indexSelected.value = null;
    itemSelected.value = null;
  }

  async function deleteItem(item) {
    const con = await confirmation();
    if(!con) return;
    const res = await service.delete(item.id);
    if (res.status != 200) return;
    await getItems();
  }

  function cancelItem() {
    clearItem();
    modalIsOpen.value = false;
  }

  function setItems(arrayList) {
    items.value = arrayList;
  }

  return {
    items,
    itemSelected,
    indexSelected,
    modalIsOpen,
    pagination,
    search,
    newItem,
    editItem,
    saveItem,
    getItems,
    deleteItem,
    cancelItem,
    setItems,
    getItemsSearch,
  };
}

<template>
  <div>
    <div class="filter-container">
      <div class="field">
        <label class="label">Vendedor</label>
        <div class="control">
          <div class="select">
            <select v-model="vendedorSelected">
              <option :value="null">Selecione...</option>
              <option v-for="(nome, index) in vendedores" :key="index">
                {{ nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label periodo">Periodo 1</label>
        <div class="control"></div>
      </div>

      <div class="filters">
        <div class="field">
          <label class="label">Data Inicio</label>
          <div class="control">
            <input
              v-model="filters.dataInicio"
              class="input"
              type="date"
              placeholder="Data Inicio"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Data Fim</label>
          <div class="control">
            <input
              v-model="filters.dataFim"
              class="input"
              type="date"
              placeholder="Data Fim"
            />
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Periodo 2</label>
        <div class="control"></div>
      </div>

      <div class="filters">
        <div class="field">
          <label class="label">Data Inicio</label>
          <div class="control">
            <input
              v-model="filters2.dataInicio"
              class="input"
              type="date"
              placeholder="Data Inicio"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Data Fim</label>
          <div class="control">
            <input
              v-model="filters2.dataFim"
              class="input"
              type="date"
              placeholder="Data Fim"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="btn-buscar-container">
      <button
        class="button is-info"
        @click="
          getItems(false);
          getItems2(false);
        "
      >
        Buscar
      </button>
    </div>

    <div style="margin-top: 20px; margin-bottom: 20px">
      <div class="field">
        <label class="label">Periodo 1</label>
        <div class="control"></div>
      </div>
      <VendedorVendasChart :height="200" :data="chartData" />
      <div class="field">
        <label class="label">Periodo 2</label>
        <div class="control"></div>
      </div>
      <VendedorVendasChart :height="200" :data="chartData2" />
    </div>

    <div class="tabs">
      <ul>
        <li
          v-for="(name, index) in tabs"
          @click="changeTab(index)"
          :key="index"
          :class="tab == index ? 'is-active' : ''"
        >
          <a> {{ name }}</a>
        </li>
      </ul>
    </div>

    <div v-if="tab == 0">
      <div v-if="data && data.length">
        <table
          class="
            table
            is-bordered is-striped is-narrow is-hoverable is-fullwidth
          "
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Orçamento</th>
              <th>Titulo</th>
              <th>Cliente</th>
              <th>Vendedor</th>
              <th>Produto</th>
              <th>Valor Total</th>
              <th>Comissão</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(venda, index) in data" :key="index">
              <td>
                {{ dateToString(venda.dataEmissao) }}
              </td>
              <td>{{ venda.orcamento }}</td>
              <td>{{ venda.titulo }}</td>
              <td>{{ venda.cliente }}</td>
              <td>{{ venda.vendedor }}</td>
              <td>{{ venda.produto }}</td>
              <td>{{ formatMoney(venda.valorTotal) }}</td>
              <td>{{ formatMoney(venda.comissao) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            class="button is-info is-light"
            @click="changePage(false)"
            :disabled="this.pagination.page === 1"
          >
            Anterior
          </button>
          <button
            :disabled="disabledNextPageComp"
            class="button is-info is-light"
            @click="changePage"
          >
            Próxima
          </button>
        </div>
      </div>
    </div>
    <div v-if="tab == 1">
      <div v-if="data2 && data2.length">
        <table
          class="
            table
            is-bordered is-striped is-narrow is-hoverable is-fullwidth
          "
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Orçamento</th>
              <th>Titulo</th>
              <th>Cliente</th>
              <th>Vendedor</th>
              <th>Produto</th>
              <th>Valor Total</th>
              <th>Comissão</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(venda, index) in data2" :key="index">
              <td>
                {{ dateToString(venda.dataEmissao) }}
              </td>
              <td>{{ venda.orcamento }}</td>
              <td>{{ venda.titulo }}</td>
              <td>{{ venda.cliente }}</td>
              <td>{{ venda.vendedor }}</td>
              <td>{{ venda.produto }}</td>
              <td>{{ formatMoney(venda.valorTotal) }}</td>
              <td>{{ formatMoney(venda.comissao) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            class="button is-info is-light"
            @click="changePage2(false)"
            :disabled="this.pagination2.page === 1"
          >
            Anterior
          </button>
          <button
            :disabled="disabledNextPage2Comp"
            class="button is-info is-light"
            @click="changePage2"
          >
            Próxima
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getVendasReportByDateAndVendedorNome,
  getVendasReportByDateAndVendedorNomeGroupMonthAndYear,
  getVendedoresNome,
} from "../services/VendedorReportService";
import moment from "moment";
import VendedorVendasChart from "../components/reports/VendedorVendasChart.vue";
export default {
  components: { VendedorVendasChart },
  data() {
    return {
      vendedorSelected: null,
      vendedores: [],
      tab: 0,
      tabs: ["Periodo 1", "Periodo 2"],
      moneyConfig: {},
      pagination: {
        page: 1,
        pages: 1,
        total: 0,
        limit: 30,
      },
      pagination2: {
        page: 1,
        pages: 1,
        total: 0,
        limit: 30,
      },
      filters: {
        encerrado: false,
        dtInicio: null,
        dtFim: null,
      },
      filters2: {
        encerrado: false,
        dtInicio: null,
        dtFim: null,
      },
      data: [],
      data2: [],
      chartData: [],
      chartData2: [],
    };
  },
  computed: {
    disabledNextPage2Comp() {
      return (
        this.pagination2.page ==
        this.lastPage(this.pagination2.limit, this.pagination2.total)
      );
    },
    disabledNextPageComp() {
      return (
        this.pagination.page ==
        this.lastPage(this.pagination.limit, this.pagination.total)
      );
    },
  },
  methods: {
    lastPage(limit, total) {
      let calc = Math.ceil(total / limit);
      if (calc <= 0) return 1;
      return calc;
    },
    changeTab(index) {
      this.tab = index;
    },
    async changePage(next = true) {
      if (!next && this.pagination.page == 1) return;

      this.pagination.page = next
        ? this.pagination.page + 1
        : this.pagination.page - 1;
      await this.getItems(true);
    },
    async changePage2(next = true) {
      if (!next && this.pagination2.page == 1) return;

      this.pagination2.page = next
        ? this.pagination2.page + 1
        : this.pagination2.page - 1;
      await this.getItems2(true);
    },
    formatMoney(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    getColorStatus(status) {
      return status === "A PRODUZIR"
        ? "green"
        : status === "ENCERRADO"
        ? "red"
        : "orange";
    },
    dateToString(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getItems2(changePage = false) {
      if (
        !this.filters2.dataInicio ||
        !this.filters2.dataFim ||
        !this.vendedorSelected
      )
        return;
      if (!changePage) {
        this.pagination2.page = 1;
        const groupRes =
          await getVendasReportByDateAndVendedorNomeGroupMonthAndYear(
            this.filters2.dataInicio,
            this.filters2.dataFim,
            this.vendedorSelected
          );
        if (groupRes.status != 200) return;
        this.chartData2 = groupRes.data.data;
      }

      const vendasRes = await getVendasReportByDateAndVendedorNome(
        this.filters2.dataInicio,
        this.filters2.dataFim,
        this.vendedorSelected,
        this.pagination2.page,
        this.pagination2.limit
      );
      if (vendasRes.status == 200) {
        this.data2 = vendasRes.data.data.data;
        this.pagination2.total = vendasRes.data.data.total;
      }
    },
    async getItems(changePage = false) {
      if (
        !this.filters.dataInicio ||
        !this.filters.dataFim ||
        !this.vendedorSelected
      )
        return;
      if (!changePage) {
        this.pagination.page = 1;
        const groupRes =
          await getVendasReportByDateAndVendedorNomeGroupMonthAndYear(
            this.filters.dataInicio,
            this.filters.dataFim,
            this.vendedorSelected
          );
        if (groupRes.status != 200) return;
        this.chartData = groupRes.data.data;
      }

      const vendasRes = await getVendasReportByDateAndVendedorNome(
        this.filters.dataInicio,
        this.filters.dataFim,
        this.vendedorSelected,
        this.pagination.page,
        this.pagination.limit
      );
      if (vendasRes.status == 200) {
        this.data = vendasRes.data.data.data;
        this.pagination.total = vendasRes.data.data.total;
      }
    },

    async getVendedores() {
      const res = await getVendedoresNome();
      if (res.status != 200) return;
      this.vendedores = res.data.data;

      if(this.vendedores.length == 1) this.vendedorSelected = this.vendedores[0];
    },
  },
  async mounted() {
    await this.getVendedores();
  },
};
</script>

<style>
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 14px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.btn-buscar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
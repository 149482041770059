<template>
  <div v-if="modelValue" class="loader-wrapper">
    <progress class="progress is-small is-primary" max="100"></progress>
  </div>
</template>

<script>
export default {
  props: { modelValue: Boolean },
};
</script>

<style>
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  z-index: 1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loading {
  position: absolute;
}
.container {
  background-color: black;
  height: 100vh;
  width: 100vw;
  opacity: 10%;
  position: absolute;
}

.progress {
  width: 5%;
}

.progress-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
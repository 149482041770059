import { Loader } from "@googlemaps/js-api-loader";
import axios  from "axios";
class GoogleMapService {
    key = "AIzaSyDM847uX2H3Nwz1fEsb6GCBdneUAzHg2UE";
    version = "weekly";
    libraries = ["places"];
    loader;
    constructor() {
        this.loader = new Loader({
            apiKey: this.key,
            version: this.version,
            libraries: this.libraries,
        });
    }

    async getPlaceFromQuery(query) {
        return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${this.key}`)
       
       
    }
  
}
export default GoogleMapService